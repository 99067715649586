.height--11px {
	height: 11px !important;
}

.height--20px {
	height: 20px !important;
}

.height--28px {
	height: 28px !important;
}

.height--30px {
	height: 30px !important;
}

.height--45px {
	height: 45px !important;
}

.object-fit--cover {
	object-fit: cover;
}
.color--primary {
	color: var(--primary-color) !important;
}

.color--accent-yellow-color {
	color: var(--accent-yellow-color);
}

.color--error-color {
	color: var(--error-color);
}

.color--success-color {
	color: var(--success-color);
}

.color-grayscale-50 {
	color: var(--greyscale-50);
}

.color-grayscale-60 {
	color: var(--greyscale-60);
}

.color-grayscale-70 {
	color: var(--greyscale-70);
}

.color-grayscale-80 {
	color: var(--greyscale-80);
}

.color-grayscale-90 {
	color: var(--greyscale-90);
}

.bg-color--success-color {
	background-color: var(--success-color);
}

.bg-transparent {
	background: transparent;
}

.bg-color--error-color {
	background-color: var(--error-color);
}

.bg-color--accent-yellow-color {
	background-color: var(--accent-yellow-color);
}

.bg-color--accent-yellow-color-10 {
	background-color: rgba(255, 145, 0, 0.1);
}

.bg-color--admin-color {
	background-color: #ffe9cc !important;
}

.bg-upload-error-color {
	background-color: #ff9797 !important;
}

.bg-color--greyscale-10 {
	background-color: var(--greyscale-10) !important;
}

.bb--solid-light-red {
	border-bottom: 1px solid var(--red-light-color);
}

.full-width {
	width: 100% !important;
}
.full-height {
	height: 100% !important;
}
.box-sizing--border-box {
	box-sizing: border-box;
}
.height--38px {
	height: 38px !important;
}

.custom-hr {
	background-color: black;
	margin: 50px 0;
}

.container {
	width: 1530px;
	padding: 0 10px;
}

.pointer-events-none {
	pointer-events: none;
}

textarea {
	resize: vertical;
}

.pointer {
	cursor: pointer;
}

.position--relative {
	position: relative;
}

.position--absolute {
	position: absolute !important;
}

.position--fixed {
	position: fixed;
}

.text-black {
	color: #111111;
}

.text-light-blue {
	color: var(--blue-color);
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-column {
	flex-direction: column;
}

.flex--1 {
	flex: 1;
}

.flex--2 {
	flex: 2;
}

.no-margin {
	margin: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.b-radius--4px {
	border-radius: 4px;
}

.b-radius--5px {
	border-radius: 5px;
}

.b-radius--6px {
	border-radius: 6px;
}

.b-radius--8 {
	border-radius: 8px;
}

.b-radius--16px {
	border-radius: 16px;
}

.b-radius-top-left-bottom-right {
	border-radius: 8px 0;
}

.border-color--white {
	border-color: var(--greyscale-0) !important;
}

.btn-default {
	&:focus {
		background-color: transparent;
		border: 1px solid #e7eaec;
	}
}

.btn-white {
	background-color: $white-color;
	border: 1px solid var(--greyscale-20);
	border-radius: 8px;
	padding: 8px 20px;
}
.indicator-popup {
	.modal-content {
		max-width: 530px;
		margin: 0 auto;
		width: 100%;
	}
}
.react-color-picker {
	width: 400px !important;
	border: 1px solid #00000026;
	box-shadow: unset !important;
	& > div {
		height: 70px;
		padding-bottom: 4% !important;
	}
	.flexbox-fix {
		padding-top: 3px !important;

		label {
			margin-top: 5px !important;
		}
	}
}
.react-stockcharts-tooltip-content {
	rect {
		stroke: var(--primary-light-color);
	}
	text {
		tspan {
			font-family: 'Outfit';
			// &:nth-child(3) {
			// 	display: none;
			// }
			// &:nth-child(4) {
			// 	font-weight: bold;
			// 	padding-top: 20px;
			// }
		}
	}
}
// .chart-component-height {
// .react-stockcharts-area {
// 	g {
// 		clip-path: none !important;
// 	}
// }
// .react-stockcharts-avoid-interaction {
// 	g {
// 		clip-path: none !important;
// 	}
// }
// .react-stockcharts-stochastic-series {
// 	g {
// 		clip-path: url('#chart-area-clip-6') !important;
// 	}
// }
// }

.btn-orange {
	background-color: var(--accent-yellow-color) !important;
	padding: 5px 10px;
	border-radius: 6px 0 0 6px !important;
}

.btn-transparent {
	background-color: transparent;
	border: 1px solid var(--greyscale-50);
}

.font-black {
	color: black;
}

.overflow--auto {
	overflow: auto;
}

.overflow--clip {
	overflow: clip;
}

.overflow-y-auto {
	overflow-y: auto;
}

.overflow-x-auto {
	overflow-x: auto;
}
.chart-component-height {
	height: calc(100% - 40px);
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.overflow-hidden {
	overflow: hidden !important;
}
.pt-0 {
	padding-top: 0 !important;
}
.pt-4 {
	padding-top: 4px !important;
}
.pt-10 {
	padding-top: 10px !important;
}

.pt-6 {
	padding-top: 6px !important;
}

.pt-8 {
	padding-top: 8px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-24 {
	padding-top: 24px;
}

.pt-75 {
	padding-top: 75px;
}

.pb-0 {
	padding-bottom: 0px;
}

.pb-10 {
	padding-bottom: 10px !important;
}

.pb-2 {
	padding-bottom: 2px;
}

.pb-4 {
	padding-bottom: 4px !important;
}

.pb-5 {
	padding-bottom: 5px;
}

.pb-6 {
	padding-bottom: 6px;
}

.pb-12 {
	padding-bottom: 12px;
}

.pb-16 {
	padding-bottom: 16px !important;
}

.pb-17 {
	padding-bottom: 17px;
}

.pb-18 {
	padding-bottom: 18px;
}

.pb-20 {
	padding-bottom: 20px !important;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-40 {
	padding-bottom: 40px !important;
}

.padding-left--15px {
	padding-left: 15px !important;
}

.container {
	width: 1530px;
	padding: 0 10px;
}

.padding--20 {
	padding: 20px;
}

.ml-0 {
	margin-left: 0 !important;
}

.ml-5 {
	margin-left: 5px !important;
}

.ml-6 {
	margin-left: 6px !important;
}

.ml-10 {
	margin-left: 10px !important;
}

.ml-15 {
	margin-left: 15px !important;
}

.ml-16 {
	margin-left: 16px !important;
}

.ml-20 {
	margin-left: 20px !important;
}

.ml-24 {
	margin-left: 24px !important;
}

.ml-25 {
	margin-left: 25px !important;
}

.ml-32 {
	margin-left: 32px !important;
}

.ml-36 {
	margin-left: 36px !important;
}

.ml-40 {
	margin-left: 40px !important;
}

.ml-45 {
	margin-left: 45px !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mr-2 {
	margin-right: 0.5rem !important;
}

.mr-4 {
	margin-right: 3px;
}

.mr-4 {
	margin-right: 4px;
}

.mr-5 {
	margin-right: 5px;
}

.mr-6 {
	margin-right: 6px;
}

.mr-8 {
	margin-right: 8px;
}

.ml-8 {
	margin-left: 8px;
}

.mr-9 {
	margin-right: 9px;
}

.mr-10 {
	margin-right: 10px !important;
}

.mr-11 {
	margin-right: 11px;
}

.mr-12 {
	margin-right: 12px;
}

.mr-13 {
	margin-right: 13px;
}

.mr-15 {
	margin-right: 15px;
}

.mr-16 {
	margin-right: 16px !important;
}

.mr-19 {
	margin-right: 19px !important;
}

.mr-20 {
	margin-right: 20px !important;
}

.mr-26 {
	margin-right: 26px;
}

.mr-30 {
	margin-right: 30px;
}

.mr-35 {
	margin-right: 35px;
}

.mr-36 {
	margin-right: 36px !important;
}

.mr-40 {
	margin-right: 40px;
}

.mr-32 {
	margin-right: 32px !important;
}

.mr-34 {
	margin-right: 34px;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-2 {
	margin-bottom: 2px !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 4px;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-9 {
	margin-bottom: 9px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-12 {
	margin-bottom: 12px !important;
}

.mb-13 {
	margin-bottom: 13px;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

.mb-17 {
	margin-bottom: 17px !important;
}

.mb-18 {
	margin-bottom: 18px;
}

.mb-19 {
	margin-bottom: 19px;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-23 {
	margin-bottom: 23px;
}

.mb-24 {
	margin-bottom: 24px !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.mb-28 {
	margin-bottom: 28px !important;
}

.mb-29 {
	margin-bottom: 29px !important;
}
.mb-30 {
	margin-bottom: 30px !important;
}

.mb-32 {
	margin-bottom: 32px;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mb-68 {
	margin-bottom: 68px !important;
}

.mb-75 {
	margin-bottom: 75px !important;
}

.mb-76 {
	margin-bottom: 76px !important;
}

.mb-125 {
	margin-bottom: 125px;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mt-4px {
	margin-top: 4px;
}

.mt-5 {
	margin-top: 2.5rem !important;
}

.mt-5px {
	margin-top: 5px !important;
}

.mt-6 {
	margin-top: 6px !important;
}

.mt-7 {
	margin-top: 7px !important;
}

.mt-8 {
	margin-top: 8px !important;
}

.mt-10 {
	margin-top: 10px;
}

.mt-12 {
	margin-top: 12px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-23 {
	margin-top: 23px;
}

.mt-24 {
	margin-top: 24px !important;
}

.mt-26 {
	margin-top: 26px;
}

.mt-28 {
	margin-top: 28px;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-31 {
	margin-top: 31px;
}

.mt-32 {
	margin-top: 32px;
}

.mt-35 {
	margin-top: 35px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-42 {
	margin-top: 42px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-52 {
	margin-top: 52px;
}
.mt-70 {
	margin-top: 70px;
}
.mt-95 {
	margin-top: 95px;
}
.mt-100 {
	margin-top: 100px;
}

.mt-6 {
	margin-top: 6px;
}

.right-0 {
	right: 0;
}

.right-10 {
	right: 10px;
}
.line-height--14px {
	line-height: 14px;
}

.line-height--17px {
	line-height: 17px !important;
}

.line-height--18px {
	line-height: 18px;
}

.line-height--19px {
	line-height: 19px;
}

.line-height--20px {
	line-height: 20px !important;
}

.line-height--22px {
	line-height: 22px !important;
}

.line-height--24px {
	line-height: 24px;
}

.line-height--25px {
	line-height: 25px;
}

.line-height--27px {
	line-height: 27px;
}

.line-height--30px {
	line-height: 30px;
}

.line-height--31px {
	line-height: 31px;
}

.font-weight--900 {
	font-weight: 900;
}

.font-weight--700 {
	font-weight: 700 !important;
}

.font-weight--400 {
	font-weight: 400 !important;
}

.font--8px {
	font-size: 8px !important;
}

.font--10px {
	font-size: 10px !important;
}

.font--11px {
	font-size: 11px !important;
}

.font--12px {
	font-size: 12px !important;
}

.font--13px {
	font-size: 13px;
}

.font--14px {
	font-size: 14px !important;
}

.font--15px {
	font-size: 15px !important;
}

.font--16px {
	font-size: 16px !important;
}

.font--18px {
	font-size: 18px !important;
}
.font--17px {
	font-size: 17px !important;
}

.font--20px {
	font-size: 20px !important;
}

.font--21px {
	font-size: 21px !important;
}

.font--22px {
	font-size: 22px;
}

.font--24px {
	font-size: 24px;
}

.font--26px {
	font-size: 26px;
}

.font--30px {
	font-size: 30px;
}

.font--40px {
	font-size: 40px;
}

.font--50px {
	font-size: 50px;
}

.font--60px {
	font-size: 60px;
}

.font--80px {
	font-size: 80px;
}

.font--371px {
	font-size: 371px;
}

.text-blue {
	color: var(--blue-color);
}

.text-yellow {
	color: var(--accent-yellow-color);
}

.text-dark {
	color: #272a30 !important;
}

.text--dark-blue {
	color: var(--primary-color);
}

.text-grey {
	color: var(--greyscale-50);
}

.text-dark--gray {
	color: var(--greyscale-80);
}

.text-light-gray {
	color: var(--greyscale-30) !important;
}

.text--cyan {
	color: var(--greyscale-10);
}

.text-dark-gray {
	color: var(--greyscale-70);
}

.d-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

.d-flex-flow-wrap {
	-ms-flex-flow: wrap !important;
	flex-flow: wrap !important;
}

.display-flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.display--block {
	display: block;
}
.align-items-start {
	align-items: flex-start !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-center {
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.align-items-end {
	-webkit-box-align: end !important;
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}

.justify-content-end {
	-webkit-box-pack: end !important;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-start {
	-webkit-box-pack: start !important;
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.justify-content-center {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.justify-content-space-between {
	-webkit-box-pack: justify !important;
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}

.justify-content-space-around {
	-webkit-box-pack: justify !important;
	-ms-flex-pack: justify !important;
	justify-content: space-around !important;
}

.justify-content-space-evenly {
	-webkit-box-pack: justify !important;
	-ms-flex-pack: justify !important;
	justify-content: space-evenly !important;
}

.text-align-center {
	text-align: center !important;
}

.text-align-left {
	text-align: left !important;
}

.text-align-right {
	text-align: right;
}
.panel-wrapper {
	position: relative;
	padding: 0 !important;

	.see-less,
	.see-more {
		position: absolute;
		bottom: -25px;
		z-index: 100;
		text-align: center;
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		color: #1ab394;
		text-transform: capitalize;
		margin: 10px;

		i {
			cursor: pointer;
			font-size: 35px;
		}
	}

	.see-less ~ .panel {
		max-height: none;
	}

	.see-less + .panel ~ .fade {
		background: 0;
	}

	.panel {
		position: relative;
		margin: 2em auto;
		max-height: 130px;
		overflow: hidden;
		-webkit-transition: max-height 0.5s ease;
		transition: max-height 0.5s ease;
	}

	.fade {
		background: -webkit-gradient(linear, left top, left bottom, from(rgba(100, 100, 100, 0)), to($white-color));
		background: linear-gradient(to bottom, rgba(100, 100, 100, 0) 0%, $white-color 100%);
		height: 100px;
		margin-top: -100px;
		position: relative;
		opacity: 1;
	}
}

.chart-container {
	margin: 0 !important;
	padding: 0 10px;

	.card-heading {
		h4 {
			width: 50%;
			line-height: 34px;
		}
	}

	.card-description-wrapper {
		min-height: 420px;
	}

	.type {
		width: 100%;
		float: right;

		.type-option {
			float: right;

			.date-selection-wrapper {
				position: relative;

				.date-label {
					line-height: 34px;
					margin-right: 5px;
				}

				.date-range-label {
					line-height: 34px;
					border: 1px solid rgba(0, 0, 0, 0.2);
					padding: 0 25px 0 15px;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					i {
						position: absolute;
						right: 10px;
						line-height: 34px;
					}
				}

				.date-preview-wrapper {
					z-index: 999;

					.static-ranges {
						position: absolute;
						right: 0;
						top: 40px;
						background-color: #f7f7f7;
						height: 315px;
						z-index: 999;

						ul {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-orient: vertical;
							-webkit-box-direction: normal;
							-ms-flex-direction: column;
							flex-direction: column;
							list-style-type: none;
							padding: 0;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							height: 100%;
							-ms-flex-pack: distribute;
							justify-content: space-around;

							li {
								width: 150px;
								padding: 0px 10px;
								height: 100%;
								text-align: center;
								cursor: pointer;
								border-bottom: 1px solid #dddddd;
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-pack: center;
								-ms-flex-pack: center;
								justify-content: center;
								-webkit-box-align: center;
								-ms-flex-align: center;
								align-items: center;

								&:hover,
								&.active {
									background-color: #1ab394;
									color: $white-color;
								}

								&:nth-last-child(1) {
									line-height: 45px;
									height: 100%;
									border-bottom: none;
								}
							}
						}
					}

					.rdr-Calendar {
						position: absolute;
						right: 150px;
						top: 40px;
						background-color: #f2f2f2 !important;
					}

					.date-range-footer {
						position: absolute;
						width: 280px;
						right: 150px;
						top: 300px;
						background-color: #f2f2f2;
						z-index: 999;

						.date-range-actions {
							width: 100%;
							height: 35px;
						}
					}
				}
			}

			input {
				width: 280px;
			}
		}
	}
}
.download-excel-popup {
	.modal-content {
		max-width: 520px;
		width: 100%;
		margin: 0 auto;
	}
	.chart-iframe {
		top: -25px;
		left: -150px;
		z-index: 2;
		.overlay {
			height: 550px;
			.loader {
				color: #ffffff !important;
			}
		}
	}
}
.download-excel-popup-big {
	.modal-dialog {
		width: 700px;
	}
	.modal-content {
		max-width: 700px;
		width: 100%;
		margin: 0 auto;
	}
	.chart-iframe {
		top: -25px;
		left: -150px;
		z-index: 2;
		.overlay {
			height: 550px;
			.loader {
				color: #ffffff !important;
			}
		}
	}
}
.locked-chart {
	position: relative;
	&::before {
		content: '';
		z-index: 9;
		background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path d='M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z'/></svg>");
		background-repeat: no-repeat;
		background-size: 30px 30px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 32px;
		height: 32px;
	}
	&::after {
		content: '';
		background-image: url('../images/locked-chart.jpg');
		width: 100%;
		height: 100%;
		min-height: inherit;
		background-position: bottom left;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		filter: blur(7px);
		-webkit-filter: blur(7px);
		display: block;
		border-radius: 8px;
	}
}

.auto-update-machine-list {
	.filter-label {
		padding: 0 8px;
		font-size: 14px;
		font-weight: bold;
	}

	.filter-table {
		tbody {
			tr {
				&:hover {
					background-color: transparent;
				}

				td {
					vertical-align: inherit !important;
				}
			}
		}
	}

	.update-machine-btn {
		height: 90px;
		padding: 14px 0;
	}
}

.filter-selection-wrapper {
	position: relative;

	.dropdown-label {
		line-height: 34px;
		font-size: 14px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		padding: 0 25px 0 15px;
		cursor: pointer;
		width: 200px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		i {
			position: absolute;
			right: 10px;
			line-height: 34px;
		}
	}

	.dropdown-option-preview-wrapper {
		z-index: 999;

		.dropdown-option {
			position: absolute;
			right: 0;
			top: 40px;
			background-color: #f7f7f7;
			border: 1px solid rgba(0, 0, 0, 0.1);
			height: 300px;
			width: 200px;
			z-index: 999;
			overflow: auto;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			ul {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				list-style-type: none;
				padding: 0;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-ms-flex-pack: distribute;
				justify-content: space-around;

				li {
					.dropdown-checkbox-wrapper {
						.checkbox-label {
							display: block;
							position: relative;
							padding-left: 35px;
							cursor: pointer;
							font-size: 14px;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-user-select: none;
							user-select: none;
							min-height: 20px;
							line-height: 20px;
							margin-bottom: 15px;
							margin-right: 15px;

							input {
								position: absolute;
								opacity: 0;
								cursor: pointer;
								height: 0;
								width: 0;

								&:checked ~ .checkmark {
									background-color: #1ab394 !important;

									&:after {
										display: block;
									}
								}
							}

							.checkmark {
								position: absolute;
								top: 1px;
								left: 0;
								width: 20px;
								height: 20px;
								background-color: #eee;

								&::after {
									content: '';
									position: absolute;
									display: none;
									left: 7px;
									top: 3px;
									width: 5px;
									height: 10px;
									border: solid white;
									border-width: 0 2px 2px 0;
									-webkit-transform: rotate(45deg);
									transform: rotate(45deg);
								}
							}

							&:hover {
								input ~ .checkmark {
									background-color: #eee;
								}
							}
						}
					}

					width: 100%;
					padding: 10px;
					height: 100%;
					text-align: center;
					cursor: pointer;
					border-bottom: 1px solid #dddddd;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: start;
					-ms-flex-pack: start;
					justify-content: flex-start;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;

					&.disabled {
						pointer-events: none;
						opacity: 0.7;
					}

					&:nth-last-child(1) {
						height: 100%;
						border-bottom: none;
					}
				}
			}

			&::-webkit-scrollbar {
				width: 3px;
			}

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			}

			&::-webkit-scrollbar-thumb {
				background-color: #1ab394;
				outline: 1px solid #1ab394;
			}
		}

		.date-range-footer {
			position: absolute;
			width: 280px;
			right: 150px;
			top: 300px;
			background-color: #f2f2f2;
			z-index: 999;

			.date-range-actions {
				width: 100%;
				height: 35px;
			}
		}
	}
}

.ui-chart-tooltip {
	pointer-events: none;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;

	.ui-chart-tooltip-content {
		width: auto;
		height: 72px;
		-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
		background-color: $white-color;
		padding: 5px 8px;

		.tooltip-heading {
			font-size: 12px;
			font-weight: bold;
			color: $white-color;
		}

		.qty,
		.date {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			padding: 8px 0 0;
			color: #000000;
			font-size: 12px;
		}
	}
}

td {
	.form-control {
		line-height: 34px !important;
	}

	::-webkit-input-placeholder {
		font-size: 11px;
	}

	:-ms-input-placeholder {
		font-size: 11px;
	}

	::-ms-input-placeholder {
		font-size: 11px;
	}

	::placeholder {
		font-size: 11px;
	}

	input,
	select {
		&::-webkit-input-placeholder {
			color: rgba(0, 0, 0, 0.8) !important;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: rgba(0, 0, 0, 0.8) !important;
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: rgba(0, 0, 0, 0.8) !important;
			opacity: 1;
		}

		&::placeholder {
			color: rgba(0, 0, 0, 0.5) !important;
			opacity: 1;
		}

		&:focus {
			background: white !important;
		}
	}
}

#page-wrapper {
	background-color: $white-color;
	transition: 0.3s margin ease-in-out;
}

.ml-50 {
	margin-left: 50px !important;
}

.ml-72 {
	margin-left: 72px;
}

.ml-100 {
	margin-left: 100px !important;
}

.mr-8 {
	margin-right: 8px;
}

.mr-24 {
	margin-right: 24px !important;
}

.mb-5 {
	margin-bottom: 2.5rem !important;
}

.mb-5px {
	margin-bottom: 5px !important;
}

.mb-6 {
	margin-bottom: 6px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

.pt--10 {
	padding-top: 10px;
}

.pt-16 {
	padding-top: 16px !important;
}

.pt-17 {
	padding-top: 17px;
}

.pt-18 {
	padding-top: 18px;
}

.pt--20 {
	padding-top: 20px;
}

.pt--30 {
	padding-top: 30px;
}

.pt--36 {
	padding-top: 36px;
}
.pt--80 {
	padding-top: 80px;
}

.pl--20 {
	padding-left: 20px !important;
}

.pl-10 {
	padding-left: 10px;
}

.pl--9 {
	padding-left: 9px;
}
.pl-24 {
	padding-left: 24px !important;
}

.pl--25 {
	@include paddingLeft(25px);
}

.pl-36 {
	padding-left: 36px !important;
}

.pl-72 {
	padding-left: 72px !important;
}

.pl-108 {
	padding-left: 108px !important;
}
.pl-144 {
	padding-left: 144px !important;
}

.pl-180 {
	padding-left: 180px !important;
}

.pl-216 {
	padding-left: 216px !important;
}

.pl-252 {
	padding-left: 252px !important;
}

.pl-288 {
	padding-left: 288px !important;
}

.pl-540 {
	padding-left: 540px !important;
}

.pr-0 {
	padding-right: 0px !important;
}

.pr--5 {
	padding-right: 5px;
}

.pr--9 {
	padding-right: 9px;
}

.pr-10 {
	padding-right: 10px !important;
}

.pl-264 {
	padding-left: 264px !important;
}

.line-height--28px {
	line-height: 28px;
}

.line-height--50px {
	line-height: 50px;
}

.line-height--88px {
	line-height: 88px;
}

.font-weight--500 {
	font-weight: 500;
}

.btl-radius--0 {
	border-top-left-radius: 0 !important;
}

.bbl-radius--0 {
	border-bottom-left-radius: 0 !important;
}

.btr-radius--0 {
	border-top-right-radius: 0 !important;
}

.bbr-radius--0 {
	border-bottom-right-radius: 0 !important;
}

#page-wrapper {
	background-color: var(--greyscale-10);
}

.sorting_asc,
.sorting,
.sorting_desc {
	&::after {
		opacity: 1 !important;
	}
}

.inactive-sort {
	color: #cccccc;
}

.search-org {
	width: 300px;
}

.filter-org {
	input {
		margin: 0 auto;
	}
}

.search-id {
	width: 10%;
}

.search-name1 {
	width: 10%;
}

.search-hw_id,
.search-auto_license,
.search-windows_version,
.search-pb_p_version,
.search-version {
	width: 10%;
}

.search-customer_id {
	width: 10%;
}

.search-name {
	width: 10%;
}

.search-label {
	width: 10%;
}

.search-labelName {
	width: 10%;
}

.search-email {
	width: 10%;
}

.search-address {
	width: 10%;
}

.search-owner {
	width: 10%;
}

.search-last_sync_at {
	width: 10%;
}

.search-last_update {
	width: 10%;
}

.search-actions {
	width: 10%;
}

.pagination-btn {
	&:hover {
		color: black;
	}
}

.pagination-active {
	background-color: var(--primary-color);
	color: $white-color;

	&:hover,
	&:focus {
		color: $white-color !important;
	}
}

.form-overlay {
	opacity: 0.5;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;

	.overlay-spinner {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 9;
	}
}

.edit-role-form-wrapper {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.checkbox-wrapper {
	input[type='checkbox'],
	label {
		cursor: pointer;
	}
}

.text-green {
	color: var(--success-color);
}

.cursor-not-allowed {
	cursor: not-allowed !important;
}

.opacity-5 {
	opacity: 0.5;
}

.opacity-1 {
	opacity: 1 !important;
}

.opacity-0 {
	opacity: 0;
}

.float-left {
	float: left;
}

.org-label {
	color: $white-color;

	span {
		border-radius: 4px;
		padding: 1px 3px;
	}
}

.customer-table {
	overflow-y: auto;
	height: calc(75vh - 250px);

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	}

	&::-webkit-scrollbar-thumb {
		background-color: #1ab394;
		outline: 1px solid #1ab394;
	}
}

.table-btn-wrapper {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.custom-abbr {
	cursor: auto !important;
	border: none !important;
	text-decoration: none;
}

body,
.modal {
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--greyscale-20);
		outline: 0;
	}
}

.table-pagination-wrapper {
	position: relative;
	text-align: right;
	width: 100%;
}

.page-count {
	float: left;
	line-height: 38px;
}

.page-selection {
	display: inline;
	width: 80px !important;

	select {
		display: inline;
		margin-right: 10px;
		width: 80px !important;
	}
}

.edit-profile-btn {
	position: absolute;
	right: 10px;
	top: 2px;
	font-size: 23px;
	cursor: pointer;

	i {
		padding-top: 2px;
	}
}

.control-label {
	margin-bottom: 5px !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.cursor-unset {
	cursor: unset !important;
}

.cursor-grabbing {
	cursor: grabbing !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.pl-15 {
	padding-left: 15px !important;
}

.pl-16 {
	padding-left: 16px !important;
}

.pr-15 {
	padding-right: 15px !important;
}

.pr-16 {
	padding-right: 16px !important;
}
.pr-20 {
	padding-right: 20px !important;
}
.pr-24 {
	padding-right: 24px;
}

.pr-72 {
	padding-right: 72px;
}
.pb-8 {
	padding-bottom: 8px;
}

.pb-16 {
	padding-bottom: 16px;
}

.pb-24 {
	padding-bottom: 24px;
}

.confirm-icon {
	color: #ed5564;
	text-align: center;

	i {
		border: 1px solid #ed5564;
		width: 50px;
		border-radius: 50%;
		padding: 10px;
	}
}

.page-heading {
	margin-bottom: 20px;
}

.input-label-wrapper {
	margin-bottom: 10px !important;
}
.shortcut--title {
	color: var(--greyscale-60);
}

.required {
	color: red;
	margin-left: 2px;
}

.table-wrapper {
	padding-top: 0;
}

.no-border {
	border: none !important;
}

.custom-card {
	position: relative;
	background-color: $white-color;
	margin: 0 10px;

	.card-heading {
		padding: 10px;
		border-bottom: 1px solid #e7eaec;

		h4 {
			margin: 0;
			letter-spacing: 0.5px;
			text-transform: capitalize;
		}
	}

	.card-description-wrapper {
		padding: 10px;

		.desc-item {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}

			label {
				margin-right: 10px;
				margin-bottom: 0;
				letter-spacing: 0.5px;
			}

			p {
				margin-bottom: 0;
				letter-spacing: 0.5px;
			}
		}
	}

	.info-label {
		padding-left: 10px;
		text-decoration: underline;
	}
}

.verify-icon,
.close-icon {
	padding-left: 8px;
	font-size: 18px;

	&.medium {
		font-size: 16px !important;
	}
}

.no-background {
	background-color: unset !important;
}

.no-border-bottom {
	border-bottom: 0 !important;
}

.border-bottom {
	border-bottom: 1px solid var(--greyscale-10);
}

.border-bottom-transparent {
	border-bottom: 3px solid transparent;
}

.border-bottom--17px-solid-white {
	border-bottom: 17px solid var(--greyscale-0) !important;
}

.profile-wrapper {
	background-color: #fff !important;
	border-radius: 10px;
	padding: 0 10%;
	font-weight: 400;
	color: #000 !important;

	.profile-field {
		padding: 10px;
		height: 30px;
		text-transform: capitalize;
	}

	.profile-value {
		padding: 10px;
	}
}

.custom-card {
	position: relative;
	background-color: $white-color;
	margin: 0 10px;

	&.profile-card,
	&.document-card {
		.card-heading {
			padding: 15px;
		}

		.user-profile-info {
			width: 200px;
			border-right: 1px solid #e7eaec;
			padding: 15px;

			.user-profile {
				width: 100px;
				margin: 0 auto 15px;

				img {
					width: 100%;
					height: 100px;
					object-fit: cover;
					border-radius: 50%;
				}
			}

			.info-wrapper {
				margin: 0 auto;
			}
		}

		.info-wrapper {
			width: 70%;
			padding: 15px;
		}

		.desc-item {
			label {
				line-height: 24px;
			}

			p {
				line-height: 1.5;
				font-size: 15px;
				font-weight: 500 !important;
			}
		}
	}

	.card-heading {
		padding: 10px;
		border-bottom: 1px solid #e7eaec;

		h4 {
			margin: 0;
			letter-spacing: 0.5px;
		}
	}

	.card-description-wrapper {
		padding: 15px;

		.desc-item {
			margin-bottom: 10px;

			.verified-icon {
				width: 15px;
				height: 15px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			label {
				margin-right: 10px;
				margin-bottom: 0;
				letter-spacing: 0.5px;
			}

			p {
				margin-bottom: 0;
				letter-spacing: 0.5px;
			}
		}
	}

	.info-label {
		padding-left: 10px;
		text-decoration: underline;
	}
}

.float-right {
	float: right !important;
}

.display-inline-flex {
	display: -webkit-inline-box !important;
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}

.login-wrapper {
	height: 100vh;
	width: 100vw;
}

.validate-card-wrapper {
	.card {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: $white-color;
		padding: 20px 10%;
		font-weight: 400;
		color: #000 !important;
		min-height: 200px;
		margin-bottom: 10px;

		.loading-icon {
			font-size: 70px !important;

			&.success {
				color: #1ab394 !important;
			}

			&.danger {
				color: #ed5565 !important;
			}
		}

		.content-wrapper {
			margin-top: 20px;
			font-size: 20px;
			text-align: center;

			.val-text {
				text-align: center;
				margin-bottom: 20px;
			}
		}
	}

	.loginscreen.middle-box {
		width: 400px;
	}

	.logo-name {
		text-align: center;
	}
}

.success-elem {
	color: #1ab394 !important;
}

.danger-elem {
	color: #ed5565 !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.table-actions {
	width: 170px;
	text-align: center;
}

.action-btn {
	min-width: 40px !important;
	width: 40px !important;
	margin-right: 5px;
}

.btn-custom-grey {
	background-color: #1d5d90;
	color: $white-color;
	fill: white;

	&:hover {
		background-color: #0f4e7e !important;
	}
}

.btn-custom-blue {
	background-color: #2f4050;
	color: $white-color;

	&:hover {
		color: $white-color;
	}

	&:focus {
		color: $white-color !important;
	}
}

.error {
	color: red;
	margin-bottom: 0 !important;
	font-size: 12px;
	margin-top: 5px;

	&::first-letter {
		text-transform: uppercase;
	}
}

body.mini-navbar .user-role-element {
	display: none !important;
}

.mini-navbar-links-wrapper {
	display: none;
	position: absolute;
	top: 0;
	right: -90px;
	width: 160px;
	height: 100%;
	z-index: 1;

	ul {
		width: 100%;

		li {
			width: 100%;
			height: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.collapse-active {
	height: auto !important;
}

body.mini-navbar .navbar-default .nav li {
	cursor: pointer;

	&:hover {
		background-color: #293846;

		.mini-navbar-links-wrapper {
			display: block;

			ul {
				display: block;

				a {
					display: block;
					width: 100%;
					height: 100%;

					&:hover {
						background-color: transparent;
					}
				}
			}
		}
	}

	a {
		text-align: center;

		i {
			margin-right: 0 !important;
		}
	}
}

.profile-element {
	cursor: pointer !important;
	color: #d6d6d6 !important;
	margin: 0 !important;
	width: 140px;
	height: 27px;
	.matflixx-icon {
		width: 140px;
		height: 27px;
	}
	img {
		object-fit: cover;
	}
}

.header-links {
	display: block;
	font-size: 14px;
	padding: 20px 15px;
	min-height: 50px;
	font-weight: 600;
	cursor: pointer;

	i {
		margin-right: 5px;
	}

	&:hover {
		background-color: $white-color;
	}
}

.navbar-static-top {
	margin-bottom: 0 !important;
}

.nav-label {
	text-transform: capitalize !important;
}

.breadcrumb-btn {
	margin-top: 28px;
	float: right;

	.back-btn {
		color: #fff !important;
	}

	button {
		font-weight: 700 !important;
	}
}

.modal-close-btn {
	padding: 0;
	position: absolute;
	right: 20px;
	z-index: 1;
	background-color: transparent;
	border: none;
	outline: 0;
	font-size: 20px;
	height: 16px !important;
	width: 16px;
	top: 20px;

	&:hover,
	&:focus,
	&:active {
		border: none;
		background-color: transparent;
	}
	.close-icon {
		fill: var(--greyscale-60);
	}
}

.modal-footer-area {
	border: none !important;
}

.user-form-wrapper {
	margin: 0 auto;

	.ibox-content {
		form {
			max-width: 500px;
			margin: 0 auto;
		}
	}

	.copy-credentials {
		font-size: 20px;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
}

.half-width {
	width: 50% !important;
}

.width--19 {
	width: 19.33% !important;
}

.width-19 {
	width: 19% !important;
}

.width--20 {
	width: 20% !important;
}

.width--25 {
	width: 25%;
}

.width-28 {
	width: 28%;
}

.width--10 {
	width: 10% !important;
}

.width--0 {
	width: 0%;
}

.width--70 {
	width: 70%;
}

.width--11px {
	width: 11px !important;
}

.width--20px {
	width: 20px !important;
}

.width--24px {
	width: 24px !important;
}

.width--34px {
	width: 34px;
}

.width--36px {
	width: 36px;
}

.width--38px {
	width: 38px;
}

.width--32px {
	width: 32px;
}

.width--52px {
	width: 52px !important;
}

.width--60px {
	width: 60px !important;
}

.width--70px {
	width: 70px !important;
}

.width-83px {
	width: 83px !important;
}

.width-100px {
	width: 100px !important;
}

.width-120px {
	width: 120px !important;
}

.width-150px {
	width: 150px !important;
}

.width--300px {
	width: 300px !important;
}

.width--500px {
	width: 500px !important;
}

.width--350px {
	width: 350px;
}
.width--400px {
	width: 400px !important;
}

.min-width-100px {
	min-width: 100px;
}

.min-width-550px {
	min-width: 550px;
}

.min-width-82px {
	min-width: 82px;
}
.width--fit-content {
	width: fit-content;
}

.min-width-120px {
	min-width: 120px;
}

.width-150px {
	width: 150px;
}

.width-190px {
	width: 190px;
}

.width-200px {
	width: 200px;
}
.width-250px {
	width: 250px;
}
.width-297px {
	width: 297px;
}
.width-500px {
	width: 500px;
}
.width--355px {
	width: 355px !important;
}
.width-40 {
	width: 40%;
}

.width--45 {
	width: 45%;
}

.width-20 {
	width: 20%;
}

.width-30 {
	width: 30%;
}

.width-32 {
	width: 32% !important;
}

.width-33 {
	width: 33.33% !important;
}

.width-34 {
	width: 34% !important;
}

.width-50 {
	max-width: calc(50% - 20px);
	width: 100%;
}

.width-60 {
	width: 60%;
}

.width--80 {
	width: 80%;
}

.width-30 {
	width: 30%;
}

.width-70 {
	width: 70%;
}

.max-width-900 {
	max-width: 900px;
}

.max-width-750 {
	max-width: 750px;
}

.non-panel-form {
	.ibox-content,
	.ibox-title {
		border: none !important;
	}
}

.license-wrapper {
	.lincese-action-wrapper {
		width: 350px;

		button {
			margin: 0 3px;
		}
	}

	.license-pagination-wrapper {
		.page-count {
			display: none;
		}
	}
}

.add-license-wrapper {
	.ibox-content {
		border-top: none;
	}
}

.send-email-wrapper {
	.ibox-content {
		border-top: none;
	}

	textarea {
		min-height: 230px;
		resize: vertical;
	}

	.label-cc {
		position: absolute;
		right: 10px;
		top: 35px;
		font-size: 14px;
		cursor: pointer;

		&:hover {
			opacity: 0.7;
			text-decoration: underline;
		}
	}

	.lang-selection {
		width: 150px;
		position: absolute;
		top: -6px;
		right: 0;
		padding-right: 0;
	}
}

.role-detail-modal-wrapper {
	::-webkit-scrollbar {
		width: 4px;
		height: 0;
	}

	::-webkit-scrollbar-thumb {
		background: #1ab394;
	}

	.modal-dialog {
		width: 50%;
		overflow: scroll;
		margin: 10px auto;

		.modal-content {
			height: 100%;
		}
	}
}

.add-role-modal-wrapper {
	::-webkit-scrollbar {
		width: 4px;
		height: 0;
	}

	::-webkit-scrollbar-thumb {
		background: #1ab394;
	}

	select {
		min-height: 500px !important;
	}

	.modal-dialog {
		width: 99%;
		height: 97%;
		overflow: scroll;
		margin: 10px auto;

		.modal-content {
			height: 100%;
		}
	}

	.modal-content {
		margin: 0 auto;
	}
}

.mini-navbar {
	.profile-dropdown {
		left: 48px !important;
		top: 45px !important;
		z-index: 2;
	}

	.navbar-default {
		.nav {
			.side-menu-shirnked {
				display: flex;
				justify-content: center;
				width: 100%;

				a {
					justify-content: center;
					width: 100%;

					svg {
						height: 24px;
						width: 24px;
					}

					.alert-count {
						width: 20px;
						height: 20px;
						padding: 2px;
						background: var(--accent-yellow-color);
						border-radius: 50%;
						font-size: 14px;
						color: var(--primary-color);
						margin-bottom: 0;
						position: absolute;
						right: 5px;
					}

					.side--menu-icon {
						display: none;
					}
				}
				.submenu-icon {
					display: none;
				}
			}
			.alert-popup {
				transform: translate(104px, 230px);
			}
		}
	}
}

.nav {
	.side-menu-shirnked {
		a {
			svg {
				height: 24px;
				width: 24px;
			}
			.alert-count {
				width: 20px;
				height: 20px;
				padding: 2px;
				background: var(--accent-yellow-color);
				border-radius: 50%;
				font-size: 14px;
				color: var(--primary-color);
				margin-bottom: 0;
				position: absolute;
				right: 116px;
			}
		}

		.submenu--list {
			a {
				.alert-count {
					margin-left: 10px;
				}
			}
		}
	}
	.alert-popup {
		transform: translate(255px, 230px);
		opacity: 1;
		top: 0 !important;
		left: 0 !important;
		background: $white-color;
		width: 350px;
		height: auto;
		box-shadow: 4px 0px 40px rgba(8, 29, 52, 0.3);
		color: black !important;
		box-sizing: border-box;
		border-radius: 8px;

		.notification {
			padding: 16px 12px;
			box-sizing: border-box;
			border-bottom: 1px solid var(--greyscale-10);

			.notification--link {
				text-decoration: underline;
				color: var(--blue-color);
			}
		}
		.notification--category {
			padding: 16px 12px;
			border-bottom: 1px solid var(--greyscale-10);

			p {
				margin-right: 10px;
			}
			span {
				color: var(--accent-yellow-color);
				margin-right: 10px;
			}
		}
	}
}

.submenu-icon {
	top: 18px;
	right: 20px;
	z-index: 2;
}

.m-t-xs {
	margin-top: 5px;
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@media (min-width: 768px) {
	.confirm-wrapper {
		.modal-dialog {
			width: 435px;
			margin: 30px auto;
		}
	}
}

.org-timeline-wrapper {
	.table-wrapper {
		.table-wrapper {
			padding-bottom: 0 !important;
		}
	}
}

.email-template-wrapper {
	background: $white-color;
	padding: 20px 40px;

	fieldset {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.input-wrapper {
		padding-left: 0;
	}
}

.license-update-disabled {
	.checkbox-input,
	.checkmark,
	.lock-btn {
		cursor: not-allowed !important;
	}
}

.checkbox-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

	.checkbox-label {
		display: block;
		position: relative;
		padding-left: 20px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		min-height: 20px;
		line-height: 20px;
		margin-bottom: 15px;
		margin-right: 15px;
		margin: 0px 15px 0px 0px;
		font-weight: 400 !important;

		&.disabled {
			opacity: 0.7;
			cursor: not-allowed;
		}

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked ~ .checkmark {
				background-color: var(--primary-color);
				border: 0;
				width: 12px;
				height: 12px;

				&:after {
					display: block;
				}
			}
		}

		.checkmark {
			position: absolute;
			top: 4px;
			left: 0;
			height: 10px;
			width: 10px;
			border: 1px solid var(--greyscale-50);
			border-radius: 2px;

			&::after {
				content: '';
				position: absolute;
				display: none;
				left: 4px;
				top: 1px;
				width: 4px;
				height: 8px;
				border: solid white;
				border-width: 0 1px 1px 0;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}
}

.manage-modules-wrapper {
	.boolean-text {
		width: 35px;
	}

	.empty-data-wrapper {
		color: red;
	}

	.item-input {
		width: 100px;
	}

	.datepicker-wrapper {
		margin: 20px 0;

		p {
			margin-bottom: 0;
			margin-right: 10px;
		}
	}

	.module-manage-date-picker {
		margin: 0 0 0 10px;

		button {
			margin-left: 10px;
		}
	}

	.add-item {
		margin: 20px 0;

		input {
			margin-right: 10px;
			width: 100px;
		}
	}

	.modules-list {
		.module {
			margin-bottom: 10px;

			.module-name {
				font-size: 16px;
				color: #2f4050;
				width: 120px;
			}

			span {
				font-size: 15px;
				letter-spacing: 0.5px;
			}
		}
	}
}

.switch {
	display: inline-block;
	height: 22px;
	margin: 0 20px;

	input {
		display: none;

		&:checked ~ small {
			background: #1ab394;
			-webkit-transition: 0.3s;
			transition: 0.3s;

			&::before {
				-webkit-transform: translate(25px, 0px);
				transform: translate(25px, 0px);
				-webkit-transition: 0.3s;
				transition: 0.3s;
				background: #fff;
			}
		}
	}

	small {
		display: inline-block;
		width: 50px;
		height: 100%;
		border: 2px solid #1ab394;
		border-radius: 30px;
		position: relative;
		cursor: pointer;

		&::before {
			content: '';
			position: absolute;
			width: 10px;
			height: 10px;
			background: #1ab394;
			border-radius: 50%;
			top: 4px;
			left: 4px;
			-webkit-transition: 0.3s;
			transition: 0.3s;
			-webkit-box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
			box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
		}
	}
}

.add-hardware-wrapper {
	.modal-body {
		overflow-y: auto;

		.collapsible-custom-header {
			padding: 10px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			position: relative;

			span {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 0.5px;
				cursor: pointer;
			}

			button {
				position: absolute;
				right: 10px;
				padding: 0;
				border: 0;
				background: none;
				font-size: 22px;
				outline: 0;
			}
		}

		.Collapsible__contentInner {
			padding: 10px 20px 0;

			.form-group {
				position: relative;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.datepicker-inline-wrapper {
	.datepicker-input-wrapper {
		label {
			margin-right: 10px;
		}

		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.datepicker-actions {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

button:disabled {
	cursor: not-allowed !important;
}

.license-disabled {
	opacity: 0.5;

	input,
	select,
	label,
	button {
		pointer-events: none;
	}
}

.switch-text {
	width: 25px;
}

.item-error {
	color: red;
	margin: 0 20%;
	text-align: right;
}

.height-100vh {
	height: 100vh !important;
}
.height--100vh-75px {
	height: calc(100vh - 75px);
}

.height--100vh-40px {
	height: calc(100vh - 40px) !important;
}

.height--100vh-115px {
	height: calc(100vh - 115px) !important;
}

.height-half {
	height: calc(100vh - 50%);
}

.min-height-100vh {
	min-height: 100vh;
}
.min-height-550 {
	min-height: 550px;
}

.min-height-150px {
	min-height: 150px;
}

.license-number-wrapper {
	td {
		::-webkit-input-placeholder {
			text-transform: capitalize;
		}

		:-ms-input-placeholder {
			text-transform: capitalize;
		}

		::-ms-input-placeholder {
			text-transform: capitalize;
		}

		::placeholder {
			text-transform: capitalize;
		}
	}

	.search-hw_id,
	.search-windows_version,
	.search-pb_p_version,
	.search-version .search-hardwarekey,
	.search-valid_from,
	.search-valid_to,
	.search-timestamp_call .search-timestamp_send,
	.search-licensenumber,
	.search-timestamp_insert,
	.search-timestamp_update {
		width: 10%;
	}

	.search-auto_license,
	.search-active,
	.search-is_locked {
		width: 5%;
	}

	.search-hardwarekey {
		width: 11%;
	}

	.status,
	.active,
	.description {
		width: 5%;
	}

	.timestamp-call,
	.timestamp-send {
		width: 9%;
	}

	.valid-from,
	.valid-to {
		width: 12%;
	}

	.hardware-id {
		width: 4%;
	}

	.license-number {
		width: 15%;
	}
}

.no-license-allowed {
	margin-top: 28px;
}

.forget-password-wrapper {
	.loginscreen.middle-box {
		width: 400px;
	}

	.logo-name {
		text-align: center;
	}
}

.language-dropdown {
	width: 100px;
	margin: 0 auto 30px;
}

.relate-license-modal {
	.modal-dialog {
		width: 50%;
		margin: 10px auto;
	}

	.modal-content {
		min-height: 500px;
	}

	.relate-customer-search {
		button {
			margin-left: 10px;
		}
	}

	.license-info-wrapper {
		margin: 20px 0 10px;
	}

	.active-customer {
		background: #e0e0e0;
		color: black;

		&:hover {
			background: #e0e0e0;
			color: black;
		}
	}
}

.new-customer-form {
	.ibox-content {
		border-top: none;
	}

	label {
		margin-top: 10px !important;
	}

	form {
		width: 80%;
		margin: 0 auto;
	}
}

.width-11pr {
	width: 11% !important;
}

.width-17pr {
	width: 17% !important;
}

.partner-link {
	i {
		color: #1ab394;
	}
}

.custom-datepicker {
	div.react-datepicker {
		font-size: 12px;
	}

	.react-datepicker__year-dropdown-container--select,
	.react-datepicker__month-dropdown-container--select {
		margin: 5px;
	}

	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 27px;
	}

	.calender-icon {
		top: 9px;
		right: 9px;
	}
}

.datepicker-actions {
	.btn {
		margin-bottom: 5px;
	}
}

.radio-input-wrapper {
	input[type='radio'] {
		display: none;
	}

	label {
		position: relative;
		padding-left: 19px;
		display: inline-block;
		font-weight: 400 !important;
		font-size: 14px;
		line-height: 20px;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 5px;
			width: 12px;
			height: 12px;
			border: 1px solid #cccccc;
			border-radius: 50%;
			background-color: $white-color;
			box-sizing: border-box;
		}

		&.radio-input {
			&::before {
				top: 9px;
			}

			&::after {
				top: 14px;
			}
		}

		&::after {
			content: '';
			width: 6px;
			height: 6px;
			background-color: var(--primary-color);
			position: absolute;
			top: 8px;
			left: 3px;
			border-radius: 50%;
			transition: all 0.2s ease;
			opacity: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		&.checked {
			color: var(--primary-color);

			&::before {
				border-color: var(--primary-color);
			}

			&::after {
				opacity: 1;
				-webkit-transform: scale(1);
				transform: scale(1);
				background-color: var(--primary-color);
			}

			&:hover {
				&::before {
					border-color: var(--primary-color);
				}
			}
		}

		&:hover {
			&::before {
				border-color: var(--primary-color);
			}
		}
	}
}

.file-upload-label {
	height: 140px;
	width: 140px;
	background-color: transparent !important;
	border: 1px solid #252525;
	color: #252525 !important;
	font-size: 25px;
}

.media-preview {
	position: relative;
	width: 140px;
	height: 140px;
	border: 1px solid #cccccc;
	display: flex;
	align-items: center;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}

	video {
		width: 100%;
		height: 100% !important;
	}

	.icon-play {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 40px;
		height: 40px;
		margin-right: -50% !important;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	.icon-remove {
		position: absolute;
		top: -8px;
		right: -8px;
		text-align: center;
		width: 27px;
		height: 27px;
		cursor: pointer;
		border-radius: 50%;
		background-color: #252525;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;

		i {
			color: $white-color;
			line-height: 25px;
			font-size: 16px;
		}
	}
}

.offer-banner__wrapper {
	width: calc(100% - 135px);

	.media-preview,
	.file-upload-label {
		width: 100%;
		height: 100px;

		img {
			object-fit: contain;
		}
	}
}

.form-loading {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba($color: $white-color, $alpha: 0.5);
	z-index: 1;
}

.media-form {
	position: relative;

	.control-label {
		margin-bottom: 10px !important;
	}
}

.custom-month-datepciker {
	.react-datepicker__month-container {
		height: 150px;
		width: 200px;

		.react-datepicker-year-header {
			font-size: 13px;
		}

		.react-datepicker__monthPicker {
			font-size: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 115px;

			.react-datepicker__month-wrapper {
				.react-datepicker__month-text {
					display: inline-block;
					width: 4rem;
					height: 2rem;
					line-height: 2rem;
					margin: 10px 5px;
				}
			}
		}
	}
}

.truncate--text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncate--lines-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
	overflow: hidden;
}

.truncate--lines-3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
	overflow: hidden;
}

.truncate--lines-1 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
	overflow: hidden;
}

.truncate--lines-6 {
	display: -webkit-box;
	-webkit-line-clamp: 6;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
	overflow: hidden;
}

.payment-detail {
	.modal-dialog {
		max-width: 800px;
		width: 100%;
	}
}

.react-datepicker-wrapper {
	display: block !important;
}

.react-datepicker__time-container--with-today-button {
	border-left: 0 !important;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item {
	font-size: 13px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	font-size: 12px !important;
}

.react-datepicker__day-name,
.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
	font-size: 11px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
	height: 184px !important;
}

.react-datepicker__day,
.react-datepicker__time-list-item {
	outline: 0;
}

.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected {
	background-color: #1d5d90 !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
	font-size: 11px;
}

.react-datepicker__input-container {
	width: 100%;
}

.stage-card {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	width: 170px;
	padding: 20px;
	position: relative;
	margin-bottom: 20px;

	&.active {
		border: 1px solid #1ab394;
	}

	.badge {
		position: absolute;
		top: 5px;
		right: 5px;
		font-size: 11px;
	}

	&.showend {
		.icon {
			svg {
				width: 50%;
				height: 50%;
			}
		}
	}

	h3 {
		margin-bottom: 20px;
	}

	.icon {
		width: 75px;
		height: 75px;
		background-color: #f2f2f2;
		border-radius: 50%;

		svg {
			width: 60%;
			height: 60%;
			fill: #2f4050;
		}
	}
}

.clear-winner-btn {
	right: 165px !important;
}

.padding-8 {
	padding: 8px;
}
.padding-5 {
	padding: 5px;
}
.image-container {
	width: 100px;
	height: 100px;
	position: relative;

	img {
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
}

.width-unset {
	width: unset !important;
}

.padding--15px {
	padding: 15px;
}

.prl-15 {
	padding: 0 15px;
}

.padding-left--15px {
	padding-left: 15px;
}

.margin-12px {
	margin: 12px;
}

.margin-bottom--15px {
	margin-bottom: 15px !important;
}

.margin-bottom--30px {
	margin-bottom: 30px !important;
}

.margin--center {
	margin: 0 auto;
}

.height-inherit {
	height: inherit;
}

.height--auto {
	height: auto !important;
}

.height--34px {
	height: 34px;
}
.height--76px {
	height: 76px !important;
}
.height--77px {
	height: 77px !important;
}

.height-100px {
	height: 100px !important;
}

.height-160px {
	height: 160px !important;
}

.height--180px {
	height: 180px !important;
}

.height--34 {
	height: 34px;
}

.height--25px {
	height: 25px !important;
}

.height-62px {
	height: 62px;
}

.height-50px {
	height: 50px !important;
}

.height-60px {
	height: 60px !important;
}

.height--100px {
	height: 100px;
}

.height--140px {
	height: 140px !important;
}

.height--250px {
	height: 250px;
}

.height-80px {
	height: 80px !important;
}

.height--90px {
	height: 90px !important;
}

.height-150px {
	height: 150px !important;
}

.height-160px {
	height: 160px !important;
}

.height--170px {
	height: 170px !important;
}
.height--333px {
	height: 333px;
}
.height--100per {
	height: 100% !important;
}
.color-white {
	color: $white-color !important;
}

.color-dark--grey {
	color: #61656a;
}

.left-unset {
	left: unset !important;
}

.left--0px {
	left: 0px !important;
}

.btn-padding-5px {
	padding: 0 5px !important;
}

.applicant-info {
	max-width: 1000px;
	margin: 0 auto;
}

.border-bottom-grayscale {
	border-bottom: 1px solid var(--greyscale-10);
}

.max-width-90 {
	max-width: calc(100% - 100px);
}

.max-width-100 {
	max-width: 100% !important;
}

.max-width-110px {
	max-width: 110px !important;
}

.max-width-150px {
	max-width: 150px;
}

.max-width-350px {
	max-width: 350px;
}

.max-width-20 {
	max-width: calc(100% - 20px);
}

.max-width--25 {
	max-width: 25px;
}

.side-links {
	right: 0px;
	top: 96px;
	button {
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
	}
}

.title-header {
	max-width: calc(100% - 50px);
	width: 100%;
}

.news-section {
	min-height: 176px;
}

.width-half {
	width: 50% !important;
}

.width-47 {
	width: 47%;
}

.font-weight--600 {
	font-weight: 600 !important;
}

.icon {
	width: 16px;
	height: 16px;
}

.table-responsive {
	overflow-x: hidden;
	height: auto;

	.table {
		border-top: 0;
		height: 97%;

		thead {
			tr {
				border: 1px solid var(--greyscale-30);

				td {
					padding: 14px 0px;
					border-right: 0;
					color: #61656a;
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 8px 10px;
				}
			}
		}
	}
}

.material-button {
	width: 140px;
	border-radius: 8px;
	color: #333;
	background-color: #fff;
}

.material-button--active {
	background-color: var(--primary-color) !important;
	color: $white-color !important;
	margin-left: -15px;
}

.active-category-card {
	border: var(--accent-yellow-color) 1px solid !important;
}

.sidebar-active {
	right: 0;
	left: 280px;
}

.modal-title {
	line-height: 30px;
	margin-bottom: 20px;
}

.popup-submit {
	border-radius: 8px;
	padding: 8px 16px;
	transition: 0.2s all ease-in-out;
	line-height: 20px;
	margin-right: 10px;
	background-color: var(--primary-color);
	color: $white-color;

	&:hover {
		background-color: var(--primary-color);
		color: $white-color;
	}

	&:active {
		background-color: var(--primary-color);
		color: $white-color;
	}

	&:focus {
		background-color: var(--primary-color);
		color: $white-color;
	}
}

.course-list-popup {
	display: flex;
	width: 414px;
	margin: 0px auto;
	height: 100vh;
	align-items: center;
	.modal-dialog {
		width: 100%;
	}
	.add-btn-active {
		background-color: var(--primary-color);
		color: $white-color;
		font-family: 'Outfit-medium';
		font-size: 14px;
		margin-top: 10px;
		padding: 6px 27px;
		border-radius: 5px;
	}

	.assign_mateiral_wrapper {
		.assign_mateiral_list {
			background-color: $white-color;
			width: 100%;
			box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
			color: var(--primary-color);
			z-index: 10;
			height: 250px;
			overflow-y: auto;
			overflow-x: hidden;
			border-radius: 5px;
			top: 33px;

			ul {
				li {
					cursor: pointer;
					&:hover {
						background-color: var(--greyscale-10);
					}
					.item {
						padding: 5px;
					}
					border-bottom: 1px solid var(--greyscale-70);
				}
			}
		}
	}
}

.ibox-content {
	padding-bottom: 0;
}

.course-range--wrapper {
	padding: 20px 0px;

	.course-range--btn {
		width: 36px;
		height: 36px;
		border-radius: 4px;
		padding: 3px;
	}

	.course-range--active {
		background-color: var(--primary-color);
		font-family: 'Outfit';
		font-size: 14px;
		font-weight: 600;
		color: $white-color;
	}
}

.currency--container {
	display: flex;
	align-items: center;
	margin: 0;

	fieldset {
		display: flex;
	}

	.form-group {
		position: inherit;
		margin: 0;

		.select {
			&::placeholder {
				color: gray;
			}
		}
	}
}

.table-date--wrapper {
	margin: 0 !important;
	border: none;
}

.price-list-popup {
	.add-btn-active {
		background-color: var(--primary-color);
		color: $white-color;
		font-family: 'Outfit-medium';
		font-size: 14px;
		margin-top: 10px;
		padding: 6px 27px;
		border-radius: 5px;
	}
}

.material-admin-search--container {
	padding: 0 20px 0 20px;
	height: calc(100vh - 75px);
	overflow: auto;
	box-sizing: border-box;
	.material-search-form {
		padding: 24px 16px 24px 24px;
		flex-wrap: wrap;
		max-width: 817px;
		.error {
			margin-bottom: 12px !important;
		}
		label {
			margin-bottom: 10px;
		}
		.user-input {
			width: 50%;
		}
		.admin-input {
			background-color: var(--red-light-color);
			padding: 10px;
			width: calc(50% - 20px);
		}
	}
	.admin--table {
		padding: 20px;
		margin-top: 24px;
		.table--container {
			border: none;
		}
		.admin-table-span {
			color: var(--accent-yellow-color);
		}
	}
	.material-search-empty-wrapper {
		color: var(--accent-yellow-color);
	}

	.back-btn {
		width: 200px;
		height: 36px;
		padding: 8px;
	}

	.add-course--btn {
		color: var(--primary-color);
		gap: 10px;
	}

	.table-pagination-wrapper {
		padding: 0;
		margin-top: 35px;
		text-align: right;
	}
}
.table-data__wrapper {
	color: var(--primary-color);
	font-size: 14px;
	margin: 0 auto;

	.table-responsive {
		overflow-x: clip;
	}

	.table--container {
		border: none;
		position: relative;
		padding-top: 30px;
		height: 100%;

		.admin--cell {
			background-color: var(--red-light-color) !important;
		}

		.table--row {
			background-color: $white-color;
			border-radius: 4px;

			.course-list-text {
				width: fit-content;

				&:hover {
					cursor: pointer;
				}
			}

			.admin--cell {
				background-color: var(--red-light-color);
			}
		}

		tr {
			td {
				padding: 12px;
				border-top-width: 0px;
				border-bottom-color: #f5f5f5;
				border-right-width: 0;
				border-bottom-width: 10px !important;
			}

			th {
				font-size: 14px;
				color: #61656a;
				background-color: transparent !important;
			}
		}

		.dropdown {
			.dropdown-menu {
				left: -143px;
			}

			.dropdown-btn {
				padding: 0;
				background: transparent;
				border: none;

				&:hover {
					background: transparent !important;
					border: none;
				}

				&:active {
					background: transparent !important;
					border: none;
				}

				&:focus {
					background: transparent !important;
					border: none;
				}
			}
		}

		.dropdown-menu {
			display: flex !important;
			flex-direction: column;

			.dropdown-item {
				padding: 6px;
				display: flex;
				justify-content: center;
				text-decoration: none;
				color: var(--primary-color);
				font-size: 14px;
				font-weight: 500;

				&:hover {
					background-color: var(--greyscale-10);
				}
			}
		}
	}
}

.table-date--wrapper {
	color: var(--primary-color);
	font-size: 14px;

	.table-responsive {
		overflow-x: clip;
	}

	.table--container {
		border: none;
		position: relative;
		padding-top: 30px;
		height: 100%;

		.table--row {
			background-color: $white-color;
			border-radius: 4px;

			.course-list-text {
				width: fit-content;

				&:hover {
					cursor: pointer;
				}
			}
		}

		tr {
			td {
				padding: 12px;
				border-top-width: 0px;
				border-bottom-color: #f5f5f5;
				border-right-width: 0;
				border-bottom-width: 10px !important;
			}

			th {
				font-size: 14px;
				color: #61656a;
				background-color: transparent !important;
			}
		}

		.dropdown {
			.dropdown-menu {
				left: -143px;
			}

			.dropdown-btn {
				padding: 0;
				background: transparent;
				border: none;

				&:hover {
					background: transparent !important;
					border: none;
				}

				&:active {
					background: transparent !important;
					border: none;
				}

				&:focus {
					background: transparent !important;
					border: none;
				}
			}
		}

		.dropdown-menu {
			display: flex !important;
			flex-direction: column;

			.dropdown-item {
				padding: 6px;
				display: flex;
				justify-content: center;
				text-decoration: none;
				color: var(--primary-color);
				font-size: 14px;
				font-weight: 500;

				&:hover {
					background-color: var(--greyscale-10);
				}
			}
		}
	}

	.export--wrapper {
		.export--btn {
			background-color: var(--primary-color);
			color: $white-color;
			font-family: 'Outfit-medium';
			font-size: 14px;
			display: flex;
			gap: 10px;
		}
	}
}

.minitable-data-wrapper {
	padding: 0px 24px 0 15px !important;
	width: 100%;
	height: calc(100% - 140px);
}

.filter-wrapper {
	.custom-form {
		display: flex;
		align-items: center;

		.ibox {
			margin: 0;
		}

		.ibox-content {
			background-color: transparent;
			color: inherit;
			padding: 0;
			border: none;
		}
	}
}

.swal2-backdrop-show {
	background-color: transparent !important;
}

.swal2-container {
	.swal2-popup {
		width: 45em;
		background: #00d304;
		font-size: 7px;
		color: $white-color;
	}
}

.group-categories--wrapper {
	padding-top: 100px;
	padding-left: 20px;
	width: 100%;

	.navbar-categories--list {
		padding: 22px 12px;
		background-color: $white-color;
		width: 95%;
		display: flex;
		border-radius: 4px;
		margin: 10px;

		&:hover {
			cursor: pointer;
		}

		.list--icon {
			padding: 0px 15px;
			color: var(--primary-color);
		}

		.navbar-categories--text {
			font-size: 14px;
			font-weight: 600;
			margin: 0;
			padding-top: 5px;
		}
	}
}

.p--6 {
	padding: 6px;
}

.br--6px {
	border-radius: 6px;
}

.br--16px {
	border-radius: 16px;
}

.br--20px {
	border-radius: 20px;
}

.border-1px-solid-greyscale-0 {
	border: 1px solid var(--greyscale-0) !important;
}

.border-1px-solid-greyscale-20 {
	border: 1px solid var(--greyscale-20) !important;
}

.border--1px-solid-grey {
	border: 1px solid var(--greyscale-50) !important;
}

.border--1px-solid-light-grey {
	border: 1px solid var(--greyscale-20);
}

.border--1px-dashed-grey-10 {
	border: 1px dashed var(--greyscale-20) !important;
}

.border-bottom--2px-solid-accent-yellow {
	border-bottom: 2px solid var(--accent-yellow-color);
}

.border--1px-solid-accent-yellow {
	border: 1px solid var(--accent-yellow-color);
}

.border-bottom--2px-solid-grayscale-30 {
	border-bottom: 2px solid var(--greyscale-30);
}

.border--2px-solid-blue {
	border: 2px solid var(--primary-color) !important;
}
.search-bar__container {
	input {
		padding-left: 38px;
	}

	.search-icon {
		position: absolute;
		left: 13px;
		top: 8px;
	}
}

.tab-position--fixed {
	box-sizing: border-box;
	background-color: $white-color;
	padding: 0 24px;
	// padding-top: 70px;
	z-index: 1;
	overflow: auto;
}
.tab {
	border-top: 1px solid #e7eaec;
	.admin-breadcrumb--btn {
		background-color: $white-color;
		z-index: 1;
		border-bottom: 1px solid #e7eaec;

		.admin--btn {
			box-sizing: border-box;
			height: 59px;
			border: 3px solid transparent;
			color: #61656a;
			padding: 18px 0px 15px 0px;
			margin-right: 26px;

			&:active {
				background-color: transparent;
			}

			&:focus {
				background-color: transparent;
				border: 3px solid transparent;
			}

			&:hover {
				background-color: transparent;
				border-bottom: 3px solid var(--accent-yellow-color);
				border-radius: 4px;
				color: var(--primary-color);
				fill: currentColor;
			}

			.admin-tab--icon {
				width: 20px;
				height: 20px;
				margin-right: 4px;
			}

			.admin--icon {
				padding-right: 5px;
				filter: grayscale(2);
				width: 20px;
				height: 20px;
			}

			.admin--fil-icon {
				padding-right: 5px;
				filter: grayscale(2);
				width: 20px;
				height: 20px;
			}

			.admin-tab--icon {
				height: 20px;
				margin-right: 4px;
				width: 20px;
			}
		}

		.admin--active {
			background-color: transparent !important;
			border-bottom: 3px solid var(--accent-yellow-color) !important;
			border-radius: 4px;
			color: var(--primary-color);

			.admin--icon {
				path {
					fill: var(--primary-color);
					stroke: var(--primary-color);
				}
			}

			.admin--fil-icon {
				path {
					fill: var(--primary-color);
				}
			}
		}
	}
}

.box {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.box input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.userList--list {
	justify-content: flex-start;
	background-color: $white-color;
	width: 280px;
	z-index: 1;
	height: calc(100% - 136px);
	top: 136px;
	.user-count {
		margin: 6px 0 10px 0;
	}
	.user-list__wrapper {
		height: calc(100% - 76px);
		overflow: auto;
		overflow-x: clip;
		overflow-y: scroll;

		.spinner-wrapper {
			.loader {
				z-index: -1;
			}
		}
	}
	.user-list-sidenav {
		padding: 6px 3px 6px 9px;

		.sidepanel-searchbar {
			width: 280px;
			background-color: $white-color;
			position: relative;

			&.search-dropdown {
				position: relative;
				&::before {
					content: '';
					width: 224px;
					height: 237px;
					position: absolute;
					top: 0;
					bottom: 0;
					border-radius: 8px;
					background-color: var(--greyscale-10);
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
				}
			}

			.search-bar__container {
				input {
					padding-left: 44px;
				}

				.filter-icon {
					cursor: pointer;
					position: absolute;
					right: 7px;
					top: calc(50% - 6px);
					width: 18px;
					height: 12px;
					fill: #929292;
				}
			}

			.filter-dropdown {
				position: absolute;
				width: 224px;
				height: 199px;
				top: 39px;
				background-color: var(--greyscale-10);
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
				padding-top: 6px;
				z-index: 999;

				.form-group {
					margin-bottom: 0 !important;
					position: relative;
					padding: 0px 0px 4px 16px;

					.clear-word {
						position: absolute;
						right: 4px;
						top: 0;
						color: #929292;
					}

					.checkbox-wrapper {
						flex-direction: column;

						.group-option__wrapper {
							.forward-arrow-icon__wrapper {
								height: 20px;
							}
						}

						.checkbox-content {
							margin-bottom: 4px;
						}
					}
				}
				.group-filter {
					height: 100%;
					.group-filter__wrapper {
						margin-bottom: 7px;
						padding-right: 4px;
						.group-search-bar__container {
							height: 20px;
							border-radius: 8px !important;
							position: relative;

							input {
								height: 20px;
								background-color: var(--greyscale-0);
								border: 1px solid #ffffff;
								border-radius: 8px !important;
								padding-left: 31px !important;

								&::placeholder {
									font: 14px;
									line-height: 20px;
									font-weight: 400;
								}
							}

							.search-icon {
								height: 15px;
								width: 15px;
								position: absolute;
								left: 9px;
								top: calc(50% - 7px);
							}

							.cross-icon__wrapper {
								position: absolute;
								right: 7px;
								top: calc(50% - 6px);
								height: 13px;
								width: 13px;
								display: flex;
								justify-content: center;
								align-items: center;
								cursor: pointer;
								.icon-icon {
									height: 13px;
									width: 13px;
								}
							}
						}

						.backward-icon__wrapper {
							cursor: pointer;
							.icon {
								height: 20px;
								width: 20px;
							}
						}

						.clear-word {
							color: #929292;
						}
					}

					.form-group {
						height: calc(100% - 27px);
						overflow: auto;
					}
				}
			}
		}
	}
	.userList-wrapper {
		border-bottom: 1px solid var(--greyscale-10);
		.userList-email {
			color: var(--greyscale-60);
			margin: 10px 20px;
		}
		&:hover {
			background-color: var(--greyscale-30);
			color: var(--primary-color);
			.userList-content {
				.userList--btn {
					font-size: 16px;
					border: none;
					padding: 10px 20px;
					text-align: start;
					border-radius: 0px;
					background-color: var(--greyscale-30);
					color: var(--primary-color);
				}
				.user-active-icon {
					border-radius: 25px;
					background: var(--success-color);
					width: 8px;
					height: 8px;
					margin-right: 20px;
				}

				.user-inactive-icon {
					border-radius: 25px;
					background: var(--error-color);
					width: 8px;
					height: 8px;
					margin-right: 20px;
					margin-top: 10px;
				}
			}
		}
		.userList-content {
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			.userList--btn {
				font-size: 16px;
				max-width: 230px;
				border: none;
				padding: 10px 20px;
				text-align: start;
				border-radius: 0px;
			}
			.user-active-icon {
				border-radius: 25px;
				background: var(--success-color);
				width: 8px;
				height: 8px;
				margin-right: 20px;
			}
			.user-frozen-icon {
				border-radius: 25px;
				background: #8ac1ff;
				width: 8px;
				height: 8px;
				margin-right: 20px;
			}
			.user-inactive-icon {
				border-radius: 25px;
				background: var(--error-color);
				width: 8px;
				height: 8px;
				margin-right: 20px;
				margin-top: 10px;
			}
		}
	}
	.userList-wrapper--active {
		background-color: var(--greyscale-30);
		color: var(--primary-color);
		.userList-email {
			color: #61656a;
			margin: 10px 20px;
		}
		.userList-content {
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			.userList--btn {
				font-size: 16px;
				border: none;
				padding: 10px 20px;
				font-weight: 600;
				text-align: start;
				border-radius: 0px;
				background-color: var(--greyscale-30);
				color: var(--primary-color);
			}
			.user-active-icon {
				border-radius: 25px;
				background: var(--success-color);
				width: 8px;
				height: 8px;
				margin-right: 20px;
			}
			.user-inactive-icon {
				border-radius: 25px;
				background: var(--error-color);
				width: 8px;
				height: 8px;
				margin-right: 20px;
				margin-top: 10px;
			}
		}
	}
	.no--data {
		color: var(--primary-color);
	}
}

.form-field {
	width: 100%;
	background-color: var(--greyscale-10);
	padding: 8px 16px;
	border: 1px solid var(--greyscale-20);
	border-radius: 8px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;

	select {
		margin: 50px;
		width: 150px;
		padding: 5px 35px 5px 5px;
		font-size: 16px;
		border: 1px solid #ccc;
		height: 34px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
}

.width--206px {
	width: 206px;
}

.drag-area {
	height: 80px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dashed var(--greyscale-30);
	border-radius: 6px;
}

.drag-area.active {
	border: 2px solid #fff;
}

.color--light-blue {
	color: var(--blue-color);
}

.display--none {
	display: none !important;
}

.border__bottom--1px-solid-blue {
	border-bottom: 1px solid var(--blue-color);
}

.color--light-grey {
	color: var(--greyscale-60);
}

.black-icon {
	path {
		fill: var(--primary-color);
	}
}
.button {
	height: 36px;
	margin-bottom: 0;
	border: 1px solid var(--greyscale-20);
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	font-size: 14px;
	border-radius: 8px;
	-webkit-user-select: none;
	user-select: none;
	box-sizing: border-box;
	font-weight: 500;

	&.button-primary {
		background-color: var(--primary-color);
		border-color: var(--primary-color);
		color: $white-color;

		&.disabled {
			background-color: var(--greyscale-50);
			cursor: not-allowed;
			border: none;
		}
	}

	&.button-secondary {
		background-color: #ffffff;
		border-color: var(--greyscale-20);

		&.disabled {
			color: var(--greyscale-50);
			cursor: not-allowed;
			border: 1px solid var(--greyscale-30);
		}
	}
	&.button-success {
		background-color: #2ecc71;
		border-color: #2ecc71;
		color: $white-color;
	}
	&.button-danger {
		background-color: var(--error-color);
		border-color: var(--error-color);
		color: $white-color;
		&.disabled {
			opacity: 0.5;
			cursor: not-allowed;
			border: none;
		}
	}
	&.button-admin {
		background-color: var(--red-light-color);
		border-color: var(--red-light-color);
		color: var(--primary-color);
	}

	&.button-additional {
		border-color: var(--primary-color);
		background-color: #ffffff;
	}

	&.button-accent {
		border-color: var(--accent-yellow-color);
		background-color: var(--accent-yellow-color);
		color: $white-color;
	}

	&.button-sky-blue {
		background-color: #8ac1ff;
		color: $white-color;

		.spinner-wrapper {
			.loader {
				&::after {
					border: 3px dotted $white-color;
				}
			}
		}
	}

	&.button-tabs {
		border-color: var(--greyscale-10);
		background-color: var(--greyscale-10);
	}

	&.button-iframe-gradient {
		background: linear-gradient(93.23deg, #f2ce85 39.41%, #1e94a4 72.32%, #679963 96.17%);
		color: #000000;
	}

	&.button-size--large {
		padding: 0 57px !important;
	}

	&.button-size--medium {
		width: 150px;
	}

	&.button-size--small {
		padding: 0 10.5px;
	}

	&.button-size--extra-small {
		padding: 0 8px;
		height: 28px !important;
	}

	&.button-border--none {
		border: 0;
	}

	&.button-icon--large {
		padding: 0 10.5px;

		svg {
			height: 13px;
			width: 13px;
		}
	}

	&.button-icon--medium {
		padding: 0 9px;
		height: 32px !important;

		svg {
			height: 13px;
			width: 13px;
		}
	}

	&.button-icon--small {
		padding: 0 8px;
		height: 28px !important;

		svg {
			height: 10px;
			width: 10px;
		}
	}

	&.button-border--blue {
		border: 1px solid #081d34;
	}

	&.button-transparent {
		background-color: transparent;
	}
}

.react-datepicker-popper {
	margin-top: 4px !important;
}

.react-datepicker {
	width: 239px;
	height: 250px;
	background-color: var(--greyscale-10) !important;
	border: 0 !important;
	border-radius: 8px;
	box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);

	.react-datepicker__triangle {
		display: none;
	}

	.react-datepicker__navigation {
		top: 18px;
		width: 7px;
		height: 7px;
	}

	.react-datepicker__navigation--next {
		border: solid var(--greyscale-70);
		border-width: 0 2px 2px 0;
		display: inline-block;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		right: 22px;
	}

	.react-datepicker__navigation--previous {
		left: 22px;
		border: solid var(--greyscale-70);
		border-width: 0 2px 2px 0;
		display: inline-block;
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}

	.react-datepicker__month-container {
		width: 100%;

		.react-datepicker__header {
			border: unset;
			padding-top: 0;

			.react-datepicker__current-month {
				display: none;
			}

			.react-datepicker__header__dropdown {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 12px 12px;
				width: 100%;
				box-sizing: border-box;

				.react-datepicker__month-dropdown-container,
				.react-datepicker__year-dropdown-container {
					.react-datepicker__month-select,
					.react-datepicker__year-select {
						padding: 0;
						font-weight: 400 !important;
						font-size: 16px !important;
						line-height: 22px !important;
						text-align: center;
						background-color: unset;
					}
				}
			}

			.react-datepicker__day-names {
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;

				.react-datepicker__day-name {
					font-weight: 500;
					font-size: 12px !important;
					line-height: 17px;
					color: var(--greyscale-50);
					text-transform: uppercase;
				}
			}
		}

		.react-datepicker__month {
			.react-datepicker__week {
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;

				.react-datepicker__day {
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					border-radius: 6px;
					margin: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					padding: 4px 0px;
				}

				.react-datepicker__day--selected,
				.react-datepicker__day--keyboard-selected {
					background-color: var(--primary-color) !important;
					color: white;
				}
			}
		}
	}

	.react-datepicker__today-button {
		display: none;
	}
}

.icon--small {
	width: 12px;
	height: 12px;
}

.padding-left-right--16px {
	padding: 0 16px;
}
.button-size--extra-small {
	padding: 0 8px;
	height: 28px !important;
}

.background-dark-blue {
	background: var(--primary-color);
}

.border-radius-left-top-right-bottom--8px {
	border-radius: 8px 0 !important;
}

.br-bottom-right-left-16px {
	border-radius: 0 0 16px 16px;
}

.border-radius--16px {
	border-radius: 16px;
}

.padding-right-left--6px {
	padding: 0 6px;
}

.backgrund-white-gray {
	background: var(--greyscale-20);
}

.background-light-gray {
	background-color: var(--greyscale-30) !important;
}

.background-white {
	background: var(--greyscale-0) !important;
}

.padding-3px {
	padding: 3px;
}

.background-transperent {
	background: transparent;
	background-color: transparent !important;
}
.bg-color--white {
	background-color: $white-color !important;
}
.bg-color--greyscale_10 {
	background-color: var(--greyscale-10);
}

.bg-color-sky-blue {
	background-color: #f5f9fc !important;
}

.z-index--1 {
	z-index: 1 !important;
}

.z-index--7 {
	z-index: 7;
}

.translate--unset {
	transform: none !important;
}
.text--plus-icon {
	color: #c8cbcf !important;
}
.date-picker--wrapper {
	.icon--wrapper {
		top: 9px;
		right: 0;
	}
}

.update-news-wrapper {
	padding: 10px 24px 40px;
	box-sizing: border-box;

	p {
		font-size: 14px;
	}
}

.height--92px {
	height: 92px;
}

.text-red {
	color: var(--error-color);
}

.search-box {
	.cross-icon__wrapper {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 19px;

		.cross-icon {
			height: 10px;
			width: 10px;
		}
	}
	.word-search-form {
		background-color: $white-color;
		border: 1px solid #e7eaec;
		color: var(--primary-color);
		padding: 8px 30px;
		width: 256px;
		border-radius: 8px;
	}
}
.dropdown-down--icon {
	transform: rotateX(0deg);
}

.dropdown-up--icon {
	transform: rotateZ(270deg);
}

.stroke--red {
	stroke: var(--error-color) !important;
}

.table-date__wrapper {
	color: #081d34;
	font-size: 14px;
	margin: 0 auto;

	.table-responsive {
		overflow-x: clip;
		height: auto;
		overflow: auto;
		max-height: 500px;

		.table--container {
			border: none;
			position: relative;
			height: auto;
			margin-top: 0 !important;
			.table--row {
				background-color: #fefefe;
				border-radius: 4px;
				td {
					border-top-width: 0px;
					border-bottom-color: #f5f5f5;
					border-right-width: 0;
					border-bottom-width: 10px;
					padding: 8px 8px 8px 16px;
				}
			}

			thead {
				position: sticky;
				background-color: var(--greyscale-10);
				top: -2px;
				tr {
					margin: 0 !important;
					border-top: 0 !important;
				}
			}
		}
	}
}

.dark-gray-bg {
	background-color: var(--greyscale-50) !important;
}
.light-orange-bg {
	background-color: var(--red-light-color) !important;
}

.user-select--none {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.bottom-popup {
	height: 40px;
	position: absolute;
	bottom: 0;
	background-color: #f8d7da;
	z-index: 9999999;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 24px;
	box-sizing: border-box;

	.bottom-popup__header {
		color: #842029;
	}
}

.text--underline {
	text-decoration: underline;
}

.chart-info--tooltip {
	top: 55px !important;
	max-width: 220px !important;
	z-index: 999;
	padding: 10px !important;
	text-align: center;
	background-color: $white-color !important;
	color: var(--primary-color) !important;
	box-shadow: (0px 4px 13px rgba(0, 0, 0, 0.15));
	font-size: 12px !important;
	line-height: 17px !important;
	font-weight: 500 !important;
	word-wrap: break-word;
}

.forecast-chart-info--tooltip {
	max-width: 190px !important;
	z-index: 999;
	padding: 10px 3px !important;
	text-align: center;
	background-color: $white-color !important;
	color: var(--primary-color) !important;
	box-shadow: (0px 4px 13px rgba(0, 0, 0, 0.15));
	font-size: 12px !important;
	line-height: 14px !important;
	font-weight: 500 !important;
	word-wrap: break-word;
}

.add-info-popup,
.add-backpack-popup,
.backpack-userlist-popup {
	.modal-dialog {
		width: 414px;
	}
}
.backpack-userlist-popup {
	.modal-body {
		max-height: calc(100vh - 62px);
		.backpack-user-list {
			.user-list__wrapper {
				max-height: calc(100vh - 170px);
				overflow: auto;
				overflow-x: clip;
				overflow-y: scroll;
				.userList-wrapper {
					border-bottom: 1px solid #f4f5f5;
					padding: 8px 12px;
					&:hover {
						background-color: #d4d6d9;
						color: var(--primary-color);
					}
				}
			}
		}
	}
}

.collapse-up {
	transform: rotateZ(90deg);
}
.collapse-down {
	transform: rotateZ(270deg);
}
.bg-color--primary {
	background-color: var(--primary-color);
}

.fill--white {
	fill: $white-color;
}

.fill--accent-yellow-color {
	fill: var(--accent-yellow-color);
}

.fill--black {
	fill: #000000;
}

.fill-none {
	fill: none;
}

.indicator-section-1 {
	max-width: 60%;
	width: 100%;
	.settings-icon {
		padding: 6px;
		border-radius: 4px;
		margin-right: 14px;
		background: var(--accent-yellow-color);
	}

	.tooltips {
		display: inline-block;
		width: calc(40% - 25px);
		background-color: var(--greyscale-10);
		padding: 8px;
		margin-top: 4px;
		border-radius: 4px;
		cursor: pointer;
		color: #444a51;
		border: 1px solid var(--greyscale-20);
		margin-right: 10px;

		.tooltiptext {
			visibility: hidden;
			width: 100%;
			overflow-y: auto;
			background-color: $white-color;
			box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
			color: black;
			text-align: center;
			border-radius: 4px;
			z-index: 12;
			top: 100%;
			left: 0;

			.indicator-option-wrapper {
				.indicator-option-list {
					margin: 0;

					.option {
						color: #444a51;
						border-bottom: 1px solid #e7e7e7;
						padding: 8px 10px;
						text-align: start;

						&:hover {
							background-color: var(--greyscale-10);
							font-weight: 500;
						}
					}
					.selected {
						background-color: var(--greyscale-10);
						position: relative;

						&::before {
							content: '';
							right: 16px;
							top: 10px;
							width: 5px;
							height: 10px;
							border: solid black;
							border-width: 0 2px 2px 0;
							-webkit-transform: rotate(45deg);
							-ms-transform: rotate(45deg);
							transform: rotate(45deg);
							display: block;
							position: absolute;
						}
					}
					.indicators-options-list {
						&:nth-child(5) {
							border-bottom: 1px solid black;
						}
					}
				}
			}
		}

		&::after {
			content: '';
			position: absolute;
			right: 12px;
			top: 12px;
			border: solid black;
			border-width: 0 3px 3px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(45deg);
		}
	}

	.tooltips:hover .tooltiptext {
		visibility: visible;
	}
}

.navbar-header {
	.search-bar__container {
		height: 100%;
		.search-input {
			margin: 4px 15px 0 0;
			height: 35px;
			width: 100%;
			outline: none;
			border-radius: 8px;
			padding: 0px 18px 0px 32px;
			border: 1px solid var(--greyscale-10);
			background-color: var(--greyscale-10);
		}
	}

	.search-result-wrapper {
		background-color: $white-color;
		width: 100%;
		box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
		color: black;
		z-index: 10;
		height: 250px;
		overflow-y: auto;
		border-radius: 5px;
		top: calc(100% + 4px);

		.search-result {
			.list {
				.item {
					padding: 10px 20px;
					border-bottom: 1.5px solid var(--greyscale-10);

					&:hover {
						background-color: var(--greyscale-10);
					}
				}
			}
		}
	}
	.date-label {
		max-width: 200px;
		width: 100%;
	}

	.search-icon {
		top: 13px;
		left: 8px;
	}
}
.fill--primary-color {
	fill: var(--primary-color) !important;
}

.fill--greyscale-80 {
	fill: var(--greyscale-80);
}

.fill--light-gray {
	fill: var(--greyscale-30);
}

.fill--accent-yellow {
	fill: var(--accent-yellow-color) !important;
}

.stroke--primary-color {
	stroke: var(--primary-color) !important;
}

.stroke--greyscale-80 {
	stroke: var(--greyscale-80);
}

.stroke-none {
	stroke: none !important;
}

.stroke--yellow {
	stroke: var(--accent-yellow-color);
}

.stroke--black {
	stroke: #000000;
}

.top-50 {
	top: 50%;
}

.top-35 {
	top: 35px;
}

.warning-input {
	border: 1px solid var(--error-color);
}

.bottom-0 {
	bottom: 0;
}

.bottom-30px {
	bottom: 30px;
}

.z-index--100 {
	z-index: 100;
}
//Design switch
.notification-switch {
	position: relative;
	display: inline-block;
	width: 120px;
	height: 36px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}

.german-slider {
	&:before {
		content: 'Aus';
	}
}

.english-slider {
	&:before {
		content: 'Off';
	}
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	border-radius: 8px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--greyscale-10);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	display: flex;
	justify-content: center;
	align-items: center;

	&.round {
		border-radius: 34px;
		&:before {
			border-radius: 25px;
		}
	}

	&:before {
		position: absolute;
		height: 30px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 52px;
		left: 4px;
		color: var(--greyscale-0);
		background-color: #081d34;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.on,
	.off {
		width: 52px;
		margin: 0;
		height: 34px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
	}
}

// input:focus + .slider {
// 	box-shadow: 0 0 1px var(--primary-color);
// }

input:checked + .german-slider:before {
	content: 'An';
}

input:checked + .english-slider:before {
	content: 'On';
}

input:checked + .slider:before {
	-webkit-transform: translateX(60px);
	-ms-transform: translateX(60px);
	transform: translateX(60px);
}

.red-dot {
	background-color: var(--error-color);
	width: 8px;
	height: 8px;
	border-radius: 50%;
}

.break-word {
	word-wrap: break-word;
}

.triangle-up {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	// box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);
	border-bottom: 20px solid var(--greyscale-0);
}

.disabled-cursor {
	cursor: not-allowed !important;
}
.height--fit-content {
	height: fit-content;
}

.add-matflixx-modal {
	.modal-dialog {
		width: 987px;
	}
}

.border-radius-8px {
	border-radius: 8px;
}

.chart-list-section {
	height: calc(100vh - 225px);
	overflow: auto;
	margin-bottom: 24px;
	flex: 2;
}

.calc-width--100px {
	width: calc(100% - 100px) !important;
}

.validty-checks {
	border: 1px solid rgba(0, 0, 0, 0.23);
	padding: 8px 11px;
	border-radius: 10px;
	max-width: 307px;
}
.text--uppercase {
	text-transform: uppercase;
}

.expired-tender {
	background-color: #f0f0f0 !important;
}

.right-axis {
	.react-stockchart-text-background {
		transform: translateX(-10px);
	}
}

.left-right-axis {
	.right {
		.react-stockchart-text-background {
			transform: translateX(-10px);
		}
	}
	.left {
		.react-stockchart-text-background {
			transform: translateX(0px);
		}
	}
}

.fill--primary-color {
	fill: var(--primary-color) !important;
}

.fill--greyscale-80 {
	fill: var(--greyscale-80);
}

.stroke--white {
	stroke: var(--greyscale-0);
}

.fill--accent-yellow {
	fill: var(--accent-yellow-color) !important;
}

.stroke--primary-color {
	stroke: var(--primary-color) !important;
}

.stroke--greyscale-80 {
	stroke: var(--greyscale-80);
}

.stroke-none {
	stroke: none !important;
}

.fill-none {
	fill: none !important;
}

.text--uppercase {
	text-transform: uppercase;
}

.gap--7 {
	gap: 7px;
}

.gap--10 {
	gap: 10px;
}

.gap-4px {
	gap: 4px;
}

.gap-32px {
	gap: 32px;
}

.gap-25px {
	gap: 25px;
}

.expired-tender {
	background-color: #e0e0e0 !important;
}

.main-data-container {
	min-height: calc(100vh - 75px);
}
.font-style--italic {
	font-style: italic;
}

.group-config-modal {
	.modal-dialog {
		max-width: 414px;
		.modal-content {
			border-radius: 16px;
		}
	}
}

.verticle-line {
	width: 1px;
	height: 20px;
	background: var(--greyscale-0);
}

.artical-config__wrapper {
	top: 0;
	right: 0;

	.config-icons__wrapper {
		border-bottom-left-radius: 11px;
		border-top-right-radius: 11px;
		padding: 5px 17px;
		background-color: var(--primary-color);
		.icon__wrapper {
			height: 18px;
			width: 18px;

			.svg-icon {
				width: 100%;
				height: 100%;
			}

			.star-icon {
				fill: var(--greyscale-0);
			}
		}
	}
	.share-icons__wrapper {
		background-color: #dfdfdf;
		padding: 4px 6px;
		max-width: 116px;
		border-radius: 3px;
		.share-svg__wrapper {
			width: 14px;
			height: 14px;
		}
	}
}

.subcategories--container {
	background-color: #fefefe;
	width: 250px;
	z-index: 1;
	height: calc(100vh - 125px);
	// transition: all 0.5s;

	&.tenders-panel-container {
		width: 310px;
		z-index: 1;
		top: 126px;
		height: calc(100vh - 128px);
		.toggle-wrapper {
			.country__button {
				padding: 5px 30px;
			}
			.sector__button {
				padding: 5px 22px;
			}
			.financer__button {
				padding: 5px 17px;
			}
		}
		.subcategories--list {
			height: calc(100% - 106px);
		}
	}

	&.close-sidebar {
		width: 0px;
		overflow: hidden;
	}

	.subcategories--list {
		height: calc(100% - 50px);
		overflow-y: auto;
		justify-content: flex-start;

		.tree-node {
			box-sizing: border-box;

			.node__wrapper {
				padding: 8px 24px;
				border-bottom: 1px solid var(--greyscale-10);
			}
		}
	}

	.active--navbar {
		background-color: var(--greyscale-30);
	}

	.no--data {
		color: var(--primary-color);
	}
}

.top-bar__wrapper {
	background-color: $white-color;
	.news-breadcrumb--btn {
		&.custom-width {
			width: calc(100% - 120px);
		}
		gap: 20px;
		border-bottom: 1px solid #e7eaec;
		padding-left: 10px;
		z-index: 4;
		overflow: auto;
		white-space: nowrap;
		&::-webkit-scrollbar {
			height: 2px;
		}

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		}

		&::-webkit-scrollbar-thumb {
			background-color: #081d34;
			outline: 1px solid #081d34;
		}
		.news--btn {
			border: 3px solid transparent;
			color: #61656a;
			padding: 20px 0px 20px 0px;

			&:active {
				background-color: transparent;
			}

			&:focus {
				background-color: transparent;
				border: 3px solid transparent;
			}

			&:hover {
				background-color: transparent;
				border-bottom: 3px solid var(--accent-yellow-color);
				border-radius: 4px;
				color: var(--primary-color);
				fill: currentColor;
			}

			.news--icon {
				padding-right: 5px;
				filter: grayscale(2);
				width: 25px;
				height: 20px;
				svg {
					width: 16px;
					height: 16px;
				}
			}
		}

		.news--active {
			background-color: transparent !important;
			border-bottom: 3px solid var(--accent-yellow-color) !important;
			border-radius: 4px;
			color: var(--primary-color);

			.news--icon {
				filter: contrast(1.5);
			}
		}
	}

	.news-extra-option {
		width: 120px;
		color: #015fc7;
		text-decoration: underline;
		padding-left: 10px;
	}
}

.freeze-user-modal {
	.modal-dialog {
		.modal-content {
			.modal-body {
				padding-bottom: 15px;
				.freezeuser-popup__wrapper {
					.matfoxx-icon__wrapper {
						.matfoxx-icon {
							width: 96px;
							height: 129px;
						}
					}
				}
			}
		}
	}
}

.filter-blur--10px {
	filter: blur(8px);
	-webkit-filter: blur(8px);
}
.not-found__wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: var(--primary-color);
	color: $white-color;
	z-index: 9999999999;

	.blind-matfoxx-icon {
		height: 319px;
		width: 235px;
	}
}

.menu-list__container {
	padding: 11px 10px 0px 10px;

	button {
		background-color: transparent;
		border: none;
	}
}

.sidenav-bottom__wrapper {
	height: 100%;
	.partner-links-swiper__wrapper {
		padding: 0 11px;
		box-sizing: border-box;
		border-radius: 9px;

		.partner-swiper {
			width: 100%;
			position: relative;

			.swiper-wrapper {
				.swiper-slide {
					border-radius: 9px;

					.sidenav-main {
						width: 100%;

						.shortcut-main {
							width: 100%;
							height: 141px;

							.image-container {
								width: 100%;
								height: 100%;
								position: relative;
								border-radius: 9px;

								.shortcut-details {
									position: absolute;
									backdrop-filter: blur(5px);
									background: #081d3480;
									border-radius: 0 0 8px 8px;
									bottom: 0;
									left: 0;
									padding: 8px 12px;
									width: 100%;
									z-index: 2;
									box-sizing: border-box;
								}

								img {
									width: 100%;
									height: 141px;
									object-fit: cover;
									border-radius: 9px;
								}
							}
						}
					}
				}
			}
		}

		.swiper-arrow__wrapper {
			height: 20px;

			.swiper-custom-pagination {
				width: calc(100% - 32px);
				transform: translateX(0%) !important;

				.swiper-pagination-bullet {
					width: 8px;
					height: 4px;
					background-color: var(--greyscale-30);
					margin-left: 0 !important;
					border-radius: 13px;

					&.swiper-pagination-bullet-active {
						background-color: var(--accent-yellow-color) !important;
						margin: 0px 4px 0px 0px;
						margin-left: 0 !important;
						transform: scale(1);
					}
					&:not(.swiper-pagination-bullet-active-main) {
						transform: scale(0) !important;
					}
				}
			}
		}
	}
}

.matfoxx-chatbot {
	z-index: 999;
	justify-content: flex-end;
	align-items: flex-end;
	width: 235px;
	height: auto;
	padding-right: 30px;
	display: flex;
	position: fixed;
	inset: auto 0% 0% auto;
	.matfoxx-ico {
		cursor: pointer;
		width: 4.5rem;
		transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		position: relative;
		transform: translate(0, 5.45rem);
		&:hover {
			transform: translate(0, 3rem);
		}
	}
	.active-matfoxx {
		transform: translate(0, 3rem) !important;
	}
	.matfoxx-ico--active {
		transform: translate(0, 0.5rem) !important;
	}
	.fox-bubble {
		opacity: 1;
		background-color: #081d34;
		border-radius: 5px 5px 0;
		padding: 10px;
		transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		position: absolute;
		bottom: 3.7rem;
		right: 7.5rem;
		box-shadow: 0 2px 5px #0003;
		.paragraph-9 {
			color: #f90;
			margin-bottom: 0;
			font-size: 14px;
			font-weight: 600;
		}
		.image-32 {
			width: 1rem;
			position: absolute;
			inset: auto -1.5px -4px auto;
		}
	}
	.fox-bubble--active {
		opacity: 0;
	}

	.chat-window {
		opacity: 0;
		pointer-events: none;
		background-color: #fff;
		border-radius: 0.9rem 0.9rem 0 0.7rem;
		flex-flow: column;
		justify-content: space-between;
		align-items: stretch;
		width: 400px;
		height: 500px;
		transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		display: flex;
		position: absolute;
		bottom: 7.5rem;
		right: 7.5rem;
		transform: translate(0, 1rem);
		box-shadow: 0 2px 5px #0003;
		.div-block-53 {
			border-radius: 10px 10px 0;
			flex-flow: column;
			width: 100%;
			height: 100%;
			display: flex;
			overflow: hidden;
			.white-poly {
				width: 0.4rem;
				position: absolute;
				inset: auto -4px 0 auto;
			}
			.chat-header {
				background-color: var(--primary-color);
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				flex: none;
				justify-content: space-between;
				align-items: center;
				padding: 12px 12px 12px 25px;
				display: flex;
				max-height: 42px;
				.paragraph-10 {
					color: var(--greyscale-0);
					margin-bottom: 0;
					font-size: 17px;
					font-weight: 700;
				}
				.cross-icon {
					height: 10px;
					width: 10px;
					fill: var(--greyscale-0);
				}
			}
			.code-embed {
				border-bottom-right-radius: 0.5rem;
				border-bottom-left-radius: 0.7rem;
				width: 100%;
				height: 100%;
				overflow: hidden;
				&::before {
					content: ' ';
					grid-area: 1 / 1 / 2 / 2;
					display: table;
				}
				&::before {
					content: ' ';
					grid-area: 1 / 1 / 2 / 2;
					display: table;
					clear: both;
				}
			}
		}
	}

	.chat-window-active {
		opacity: 1;
		pointer-events: auto;
		transform: translate(0);
	}
}

.chatbot-active {
	bottom: 0%;
}

.forecast-blur-color {
	// background: rgba(255, 255, 255, 0.01);
	// backdrop-filter: blur(4.85px);
	filter: blur(4px) !important;
	transition: filter 0.3s ease-in-out !important;
}

.forecast-color {
	stop-color: #ff9100;
}

.blur {
	filter: blur(4px) !important;
	transition: filter 0.3s ease-in-out !important;
}

.no-blur {
	filter: none;
	transition: filter 0.3s ease-in-out;
}

.admin-course {
	.modal-dialog {
		width: calc(100% - 250px);
		margin: 16px 16px 16px auto;

		.modal-content {
			margin-left: 24px;
		}
	}
}

.news-popup {
	.submit-btn {
		border-radius: 8px;
		padding: 8px 16px;
		transition: 0.2s all ease-in-out;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		margin-right: 10px;
		background-color: var(--primary-color);
		color: $white-color;
		width: fit-content;

		&:hover {
			background-color: var(--primary-color);
			color: $white-color;
		}

		&:active {
			background-color: var(--primary-color);
			color: $white-color;
		}

		&:focus {
			background-color: var(--primary-color);
			color: $white-color;
		}
	}
	.alert-tooltip {
		max-width: 250px !important;
		z-index: 999;
		padding: 10px !important;
		text-align: center;
		background-color: var(--greyscale-0) !important;
		color: var(--primary-color) !important;
		box-shadow: (0px 4px 13px rgba(0, 0, 0, 0.15));
		font-size: 12px !important;
		line-height: 17px !important;
		font-weight: 500 !important;
		word-wrap: break-word;
	}
}

.disabled {
	pointer-events: none;
	user-select: none;
	cursor: none;
	caret-color: transparent;
}
