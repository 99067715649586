@media screen and (max-width: 2100px) {
	.react-datepicker {
		.react-datepicker__navigation--previous {
			left: 15px;
		}
		.react-datepicker__navigation {
			top: 20px;
		}
		.react-datepicker__navigation--next {
			right: 13px;
		}
	}
}
@media only screen and (max-width: 1800px) {
	.futuretable-container {
		max-width: calc(100% - 20px);
		width: 100%;
	}
}

@media only screen and (max-width: 1420px) {
	.react-stockcharts-crosshair-cursor {
		transform: translate(-6px, -6px);
	}
	.sortable__wrapper {
		display: flex !important;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		grid-gap: unset !important;
		.favourite-item__wrapper {
			height: 437px;
			margin-bottom: 16px;
			&:nth-child(1),
			&:nth-child(3),
			&:nth-child(5),
			&:nth-child(7) {
				width: calc(50% - 16px) !important;
				margin-right: 16px;
			}

			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(6) {
				width: 50% !important;
			}

			.card-items {
				.swiper {
					.swiper-wrapper {
						.swiper-slide {
							.news-card__wrapper {
								p {
									display: -webkit-box;
									-webkit-line-clamp: 4;
									/* autoprefixer: off */
									-webkit-box-orient: vertical;
									/* autoprefixer: on */
									overflow: hidden;
								}
							}
						}
					}
				}
			}
		}
	}

	.user-material-container {
		.search-container {
			.search-bar__container {
				width: 300px;
			}
		}
	}
}

@media screen and (max-width: 1400px) {
	.futuretable-section {
		.futuretable-main {
			.graph-container {
				.chart-container {
					.chart-indicators {
						.indicator-section-1 {
							.tooltips {
								width: calc(20% - 25px);
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.news-article_wrapper {
		height: calc(100vh - 320px) !important;
	}
	.tender-article_wrapper {
		height: calc(100vh - 360px) !important;
	}
}
@media screen and (max-width: 1300px) {
	.table-pagination-wrapper {
		.btn-group {
			.pagination-btn {
				padding: 5px 15px;
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.article--box {
		height: 357px !important;
		flex-wrap: wrap;
		.article-image--wrapper {
			width: 100% !important;
		}
	}
	.save-news-image--wrapper,
	.article-image--wrapper {
		max-width: none !important;
	}

	.article--stories {
		max-width: none !important;
		padding-left: 5px !important ;
	}
	.edit-form-container {
		flex-direction: column;
		.edit-form-general {
			width: auto !important;
			margin: 0;
		}
		.edit-form-permission {
			width: auto !important;
			margin-top: 24px;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.profile-container {
		flex-direction: column;
		.profile-info__wrapper {
			margin: 0 0 24px 0;
			width: auto !important;
		}
	}
	.user-details-container {
		.user-details-box {
			flex-direction: column;
			height: auto !important;
			.user-details-profile-wrapper,
			.user-details-wrapper,
			.user-details-action-wrapper {
				width: 100%;
			}

			.user-details-wrapper {
				padding-top: 0;
			}
			.user-details-action-wrapper {
				padding: 0 0 20px 0;
				justify-content: start !important;
				.user-details-btn {
					margin-left: 20px;
				}
			}
		}
	}
	.costmodal-page__wrapper {
		.cost-modal-table__wrapper {
			.table-responsive {
				overflow-x: auto;
				table {
					table-layout: unset !important;
				}
			}
		}
	}
	.news-article_wrapper {
		height: calc(100vh - 350px) !important;
	}
	.tender-article_wrapper {
		height: calc(100vh - 430px) !important;
	}
}
@media only screen and (max-width: 992px) {
	.mini-navbar {
		.submenu-icon {
			display: none;
		}
		.nav {
			.side-menu-shirnked {
				a {
					.alert-count {
						display: none;
					}
				}
			}
		}
	}
	.material-search-container {
		flex-direction: column;
	}
	.user-input {
		width: calc(100% - 20px) !important;
	}
	.admin-input {
		margin-top: 20px;
		width: calc(100% - 20px) !important;
	}

	.navbar-default {
		background-color: #081d34 !important;
		transition: all ease 0.3s !important;
	}
	#page-wrapper {
		margin: 0 0 0 100px !important;

		.navbar {
			width: calc(100% - 170px);
		}
	}
}

@media (min-width: 768px) {
	#page-wrapper {
		position: inherit;
		margin: 0 0 0 250px;
	}

	.navbar-static-side {
		z-index: 2001;
		position: fixed;
		height: 100%;
		width: 250px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.navbar-top-links .dropdown-messages,
	.navbar-top-links .dropdown-tasks,
	.navbar-top-links .dropdown-alerts {
		margin-left: auto;
	}
}

@media only screen and (max-width: 768px) {
	#page-wrapper {
		position: inherit;
		margin: 0 0 0 250px;
		min-height: 100vh;
	}
	.navbar-static-side {
		z-index: 2001;
		position: fixed;
		height: 100%;
		width: 250px;
	}

	.navbar-top-links .dropdown-messages,
	.navbar-top-links .dropdown-tasks,
	.navbar-top-links .dropdown-alerts {
		margin-left: auto;
	}

	.table-responsive {
		border: none !important;
	}
	.news-article_wrapper {
		height: calc(100vh - 430px) !important;
	}
}

@media only screen and (max-height: 850px) {
	.login__wrapper {
		.matflixx-icon__wrapper {
			.matflixx-icon {
				width: 380px;
				height: 100px;
			}
		}
		p {
			margin-bottom: 90px;
		}

		.login-footer {
			margin-top: 70px !important;
		}
	}
}

@media only screen and (max-height: 768px) {
	.login__wrapper {
		overflow: scroll !important;
	}
}

//IFRAME MOBILE VIEW
@media only screen and (max-width: 640px) {
	.iframe-container {
		.matflixx-bme-icon__wrapper {
			justify-content: center !important;
		}

		.widget-container {
			margin-bottom: 18px !important;
		}
	}
}
